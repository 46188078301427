import { timezones } from "@aidkitorg/types/lib/timezones";
import { memoize } from "../Util";

export type TimeZoneId = typeof timezones[number]['utc'][number];

export const getTimezonesByName = memoize((timeZoneId: TimeZoneId) => timezones.filter( t => t.utc.includes(timeZoneId as never)));

/*
 * accepts a timeZoneId (eg. 'America/Chicago') and optionally a date (defaults to now), and receive a timezone object with name, abbreviation, and offset in hours
*/
export function getTimeZoneData(timeZoneId: TimeZoneId, d: Date = new Date()) : typeof timezones[number] | undefined {
  const timezonesByName = getTimezonesByName(timeZoneId as never);

  if (!timezonesByName.length) {
    return;
  }
  if ( timezonesByName?.length === 1 ) {
    return timezonesByName[0];
  }

  let formatError;
  // use formatters to determine UTC offset
  const formattedComponents = [

    new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
      timeZone: timeZoneId
    }),
    new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
      timeZone: 'UTC',
    })
  ].map( formatter => {
    try {
      const formatted = formatter.format(d);
      const [day, time] = formatted.split(', ');
      const [hour, min] = time.split(':');
      return [day, hour, min].map(v => parseInt(v, 10));  
    }
    catch(err) {
      formatError = err;
      return [];
    }
  });

  if (formatError) {
    return timezonesByName[0];
  }

  const [[tzDay, tzHour, tzMinute], [utcDay, utcHour, utcMinute]] = formattedComponents;

  let dayDelta = tzDay - utcDay;
  if (dayDelta > 1) {
    dayDelta = -1
  }
  else if (dayDelta < -1) {
    dayDelta = 1;
  }

  const offset = (dayDelta)*24 + (tzHour - utcHour) + (tzMinute - utcMinute)/60;
  // return the matches timezones name and offset, or the first timezone that matches the offset
  return timezonesByName.find(tz => tz.offset === offset) || timezones.find(t => t.offset === offset);
}
