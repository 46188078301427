import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "@aidkitorg/component-library";
import { usePost, useAPIUpload } from "../../API";
import InterfaceContext, { ConfigurationContext, SupportedLanguage } from "../../Context";
import { useLocalizedStrings } from "../../Localization";
import { ArrowPathIcon, CameraIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { SpacedSpinner, useInterval, useMarkdown } from "../../Util";
import { handleErrors } from "./LivenessErrors";
import { LivenessState, LivenessStage, LivenessDetection } from "./types";
import { useIntervalRecording } from "./RecorderV2";
import { FinalPage } from "./FinalPage";
import { backZoom, facingModeForStage } from "./consts";
import { Button, WhiteButton } from "./Buttons";
import { CardFront, DebitCardFront, CardBack, Selfie } from "./CoverImages";
import { Attachment } from "../../Questions/Attachment";
import { getURLsFromCSV } from "@aidkitorg/roboscreener/lib/util/urls_from_csv";
import { useMultipartUpload } from "../../utils/useMultipartUpload";

let debug = process.env.NODE_ENV === 'development' ? console.log : () => {};

function getScreenshot(videoEl: HTMLVideoElement): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const settings = ((videoEl.srcObject!) as any).getVideoTracks()[0].getSettings()
  canvas.width = settings.width;
  canvas.height = settings.height;
  const context = canvas.getContext('2d');
    
  context!.drawImage(videoEl, 0, 0, canvas.width, canvas.height);

  // Sometimes the video frame is not ready and we need to try again.
  // Detect if the canvas is black and try again if so
  // (Limit to 3 attempts so we don't get stuck)
  let isBlack = true;
  for (let i=0; i<3; i++) {
    const pixelData = context!.getImageData(0, 0, canvas.width, canvas.height).data;
      
    for (let i = 0; i < pixelData.length; i += 4) {
      if (pixelData[i] !== 0 || pixelData[i + 1] !== 0 || pixelData[i + 2] !== 0) {
        isBlack = false;  // Found a non-black pixel
        break;
      }
    }

    if (isBlack) {
      console.log("Canvas is black", i+1, "trying again");
      context!.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
    }
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      resolve(blob!)
    }, "image/jpeg", 1.0);
  });
}

export function fitRectInRect(inner: {width: number, height: number}, outer: {width: number, height: number}) {
  let scale = Math.min(outer.width / inner.width, outer.height / inner.height);
  return {
    width: Math.ceil(inner.width * scale),
    height: Math.ceil(inner.height * scale),
    top: Math.round((outer.height - (inner.height * scale)) / 2),
    left: Math.round((outer.width - (inner.width * scale)) / 2),
  }
}

export function LivenessDetectionSessionPage(props: any) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const { livenessId } = useParams() as any;
  const { toast } = useToast();

  const loggerHandler: ProxyHandler<string[]> = {
    get: function(target, prop, receiver) {
      if (prop === 'push') {
        return function(element: string) {
          
          if (['development'].includes(process.env.NODE_ENV)) {
            return Reflect.apply(target[prop], target, [`${new Date().toISOString()} ${process.env.NODE_ENV} ${element}`]);
          }
        }
      }
      return Reflect.get(target,prop,receiver);
    }
  }
  const logger = useRef(new Proxy([] as string[], loggerHandler));

  const [videoMode, setVideoMode] = useState(process.env.NODE_ENV === 'development');
  if (process.env.NODE_ENV === 'development') {
    (window as any).setVideoMode = setVideoMode;
    (window as any).getLog = () => console.log(logger.current.join('\n'));
  }

  /* Camera management */
  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const [mediaStream, setMediaStream] = useState(null as MediaStream | null);
  const videoStream = useRef(null as MediaStream | null);

  const [cameraAvailable, setCameraAvailable] = useState(true);
  const [multipleCameras, setMultipleCameras] = useState(false);
  const [facingMode, setFacingMode] = useState("environment" as "user" | "environment");
  const [videoDimensions, setVideoDimensions] = useState<{
    width: number,
    height: number
    top: number,
    left: number
  } | null>(null)
  const [maskDimensions, setMaskDimensions] = useState<{
    width: number,
    height: number
    top: number,
    left: number
  } | null>(null)

  const [videoPlaying, setVideoPlaying] = useState(false);
  const resizeRef = useRef<any>(null);

  const [idType, setIDType] = useState<"wide" | "tall">("wide");
  const [shouldFail, setShouldFail] = useState<Partial<Record<keyof LivenessState, boolean>>>({})

  /* Status management */
  const getLivenessSession = usePost("/applicant/get_liveness_session", { handleErrors: (data) => { handleErrors(data, (payload) => toast(payload)) } });
  const updateLivenessAttempt = usePost("/applicant/update_liveness_attempt");
  const getUploadURL = usePost("/document/upload_url");
  const checkRealtimeResults = usePost("/applicant/check_realtime_results");
  const upload = useAPIUpload("/upload");
  const multipartUpload = useMultipartUpload();

  useEffect(() => {
    if (multipartUpload.error) {
      console.warn("Error uploading", multipartUpload.error);
      toast({
        description: "Error uploading",
        variant: 'error'
      });
    }
  }, [multipartUpload.error]);

  // The config from Distro
  const [livenessConfig, setLivenessConfig] = useState(null as null | LivenessDetection);
  const [livenessState, setLivenessState] = useState({ front: {}, back: {}, selfie: {} } as LivenessState);
  const [actionState, setActionState] = useState<null | LivenessStage>(null);
  const [currentImage, setCurrentImage] = useState(null as null | Blob);
  const [currentVideo, setCurrentVideo] = useState(null as null | string);
  const [realtimeResults, setRealtimeResults] = useState<null | Awaited<ReturnType<typeof checkRealtimeResults>>>(null);
  const [retrying, setRetrying] = useState(false);
  const [canCloseWindow, setCanCloseWindow] = useState(false);

  const getNextActionState = (cur: null | LivenessStage) => {
    // If we're retrying we want to allow people to pop in/out of the specific
    // failed state
    if (cur?.includes('-captured') && retrying) return 'waiting';

    switch (cur) {
      case 'front':
      case 'back':
      case 'selfie':
        return cur + "-captured";

      case 'front-captured':
        return 'back';
      case 'back-captured':
        return 'selfie';
      case 'selfie-captured':
        return 'waiting';
      default:
        return cur;
    }
  }

  // On load, get information about the liveness session
  useEffect(() => {
    // Fetch the liveness config
    (async () => {
      const resp = await getLivenessSession({ livenessId });
      if (resp.livenessConfig) {
        setLivenessConfig(resp.livenessConfig);
        setActionState(resp.livenessConfig && !resp.livenessConfig.identification ? 'selfie' : 'front');
      }
    })();
  }, [])

  // Check the feedback
  useInterval(() => {
    if (actionState === 'done') return;
    (async () => {
      setRealtimeResults(await checkRealtimeResults({ livenessId })) 
    })();
  }, 3000);

  const handleResize = useCallback(() => {
    const elWidth = videoRef.current?.offsetWidth;
    const elHeight = videoRef.current?.offsetHeight;

    const width = mediaStream?.getVideoTracks()[0].getSettings().width;
    const height = mediaStream?.getVideoTracks()[0].getSettings().height;

    if (width && height && elWidth && elHeight) {
      const dimensions = fitRectInRect({ width, height }, { width: elWidth, height: elHeight }); 

      const maskDimensions = fitRectInRect(actionState == 'selfie' ? 
        { width: 690, height: 870 } :
        actionState === 'front' ? { 
          width: idType === 'tall' ? 560 : 820,
          height: idType === 'tall' ? 820 : 560
        } : { width: 820, height: 560 },
      { width: dimensions.width, height: dimensions.height });
      maskDimensions.top += dimensions.top;
      maskDimensions.left += dimensions.left;

      setVideoDimensions(dimensions);
      setMaskDimensions(maskDimensions);
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  }, [mediaStream, videoRef.current, actionState, idType])

  useEffect(() => {
    handleResize();
  }, [idType]);

  /* Layout Management */
  useEffect(() => {
    // Initialization tends to happen in fairly arbitrary orders so we need a bunch of
    // different handlers to ensure we've catch things when they finally settle
    const localHandleResize = () => { handleResize() };

    localHandleResize();
    function handlePlay() { localHandleResize(); }
    function handlePause() { setVideoPlaying(false); }
    videoRef.current?.addEventListener('resize', localHandleResize);
    videoRef.current?.addEventListener('play', handlePlay)
    videoRef.current?.addEventListener('pause', handlePause)
    window.addEventListener('resize', localHandleResize);
    
    return () => {
      videoRef.current?.removeEventListener('resize', localHandleResize);
      videoRef.current?.removeEventListener('play', handlePlay);
      videoRef.current?.removeEventListener('pause', handlePause);
      window.removeEventListener('resize', localHandleResize);
    }
  }, [mediaStream, videoRef.current, actionState]);

  /* Camera Management */
  const updateCamera = useCallback(async (facingMode: string, actionState: string) => {
    // Clear the video stream so that we know we arent streaming immediately.
    videoStream.current = null;

    if (mediaStream && videoRef.current) {
      console.log("Swapping cameras");
      videoRef.current.pause();
      videoRef.current.srcObject = null;
      mediaStream.getTracks().forEach((track) => {
        console.log("Stopping", track.label);
        track.stop();
      });
    }

    if (actionState !== "front" && actionState !== "back" && actionState !== "selfie") {
      // kill the media stream 
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          console.log("Stopping", track.label);
          track.stop();
        });
      }
      return;
    };

    // If the screen is vertical try to ask for a vertical image
    let width = 1920;
    let height = 1440;
    if (window.innerHeight > window.innerWidth) {
      width = 1440;
      height = 1920;
    }

    const requestedMedia = {
      audio: false,
      video: { 
        facingMode,
        width: { ideal: width },
        height: { ideal: height },
        resizeMode: "none"
      },
    };

    let stream: MediaStream | undefined;
    try {
      stream = await navigator.mediaDevices.getUserMedia(requestedMedia) as MediaStream;;
      console.log("New stream created:", stream.id, facingMode, actionState);
      setMediaStream(stream);
      videoStream.current = stream;
    } catch (e) {
      console.warn("Error creating stream:", e);
      setCameraAvailable(false);
    }
    let devices;
    try {
      devices = await navigator.mediaDevices.enumerateDevices();
      let videoCount = 0;
      for (const device of devices) {
        if (device.kind === 'videoinput') {
          videoCount++;
        }
      }
      if (videoCount > 1) {
        setMultipleCameras(true);
      }
    } catch (e) {
      console.log("No Camera Available 2");
    }
  }, [mediaStream]);

  useEffect(() => {
    updateCamera(facingMode, actionState || "");
  }, [facingMode, actionState]);

  function handleCanPlay() {
    console.log("Playing", mediaStream);
    videoRef.current.play();
  }
    
  useEffect(() => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
      handleCanPlay();
    }
  }, [mediaStream]);

  const swapCamera = useCallback(() => {
    //setUploaded(false);
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  }, [facingMode]);

  const { recordedChunks, codec, captureReady, stopRecording } = useIntervalRecording(
    mediaStream, 
    logger.current
  );

  const [capturing, setCapturing] = useState(false);

  const capture = async (part: 'selfie' | 'front' | 'back') => {

    logger.current.push(`Capturing ${part}`);

    setCapturing(true);

    // Upload the Image
    let uploadImage = async () => {
      let image = await getScreenshot(videoRef.current);
      if (!image) return;
      setCurrentImage(image);
      const url = await getUploadURL({
        path: part,
        length: image.size
      });
      
      let result;
      try {
        result = await upload([image], {}, url.uploadURL, "PUT", (progress) => setLivenessState((cur) => {
          return {
            ...cur,
            [part]: {
              ...cur[part],
              imageUpload: progress,
            }
          }
        }))
      } catch (e) {
        console.warn("Error uploading image: ", e);
        toast({
          description: "Error uploading image",
          variant: 'error'
        });
      }
      return url.savedPath;
    }

    let videoPromise = async () => {
      // Wait for the video to stop
      stopRecording();
      // Wait 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000));

      //      const currentChunks = recordedChunks.current;
      const currentChunks = recordedChunks.current;

      // Upload the last three recordings, separately
      const lastFew = Object.entries(currentChunks).sort(([a,x],[b,y]) => parseInt(a) - parseInt(b)).slice(-3);

      console.log("Last Few length:", lastFew.length);

      let videoUploadCounter = 0;
      let videoUploadTasks: Promise<string | undefined>[] = [];
      let videoUploadProgress: Record<number, number> = {};

      function blobToBlobParts(blob: Blob, partSize: number = 1024 * 1024): BlobPart[] {
        const blobParts: BlobPart[] = [];
        const totalSize = blob.size;
        let start = 0;
      
        while (start < totalSize) {
          const end = Math.min(start + partSize, totalSize);
          const chunk = blob.slice(start, end);
          blobParts.push(chunk);
          start = end;
        }
      
        return blobParts;
      }

      for (const [i, chunks] of lastFew) {
        let counter = ++videoUploadCounter;
        videoUploadTasks.push((async () => {
          const videoBlob = new Blob(chunks.map(c => c.data), { type: codec.current });
          if (videoBlob.size === 0) return;

          logger.current.push(`latestVideoLength: ${chunks.length}, videoblob: ${videoBlob}`);
          const codecExt = codec.current.includes('mp4') 
            ? 'mp4' 
            : codec.current.includes('webm') ? 'webm' : 'ogg';
          
          const blobParts = blobToBlobParts(videoBlob);

          const res = await multipartUpload.startUpload(new File(blobParts, livenessId + "_" + part + "_video_" + counter + "." + codecExt), {
            pathParams: {
              kind: 'liveness',
              livenessId, 
              videoIndex: counter,
              part
            },
            contentType: codec.current.split(";")[0] as "video/webm" | "video/mp4",
            length: videoBlob.size
          });

          console.log("[FINISHED UPLOADING] Got location of hupload:", res?.location);
          return decodeURIComponent(res?.location || '');
        })());
      }
      
      // for showing progress
      let interval = setInterval(() => {
        setLivenessState((cur) => {
          return {
            ...cur,
            [part]: {
              ...cur[part],
              // video upload progress is based on the slowest video
              videoUpload: Object.values(videoUploadProgress).reduce((acc, cur) => Math.min(acc, cur), 1)
            }
          }
        })
      }, 200);

      let promiseResults = (await Promise.allSettled(videoUploadTasks));
      // wait for one more tick
      await new Promise((resolve) => setTimeout(resolve, 300));
      clearInterval(interval);

      // Let the backend know our results
      let videoPaths = promiseResults.map((p) => p.status === 'fulfilled' ? p.value : undefined);
      return videoPaths;
    }

    setActionState(part + "-captured" as any);

    const [imagePath, videoPaths] = (await Promise.allSettled([
      uploadImage(), 
      videoPromise()
    ])).map((p) => p.status === 'fulfilled' ? p.value : undefined);

    const completedVideos = (videoPaths as string[]).filter(v => !!v);
    // For debug, just look at last video
    if (videoPaths && videoMode) setCurrentVideo(completedVideos.slice(-1)[0]);

    setCapturing(false);

    setLivenessState((cur) => {
      return {
        ...cur,
        [part]: {
          ...cur[part],
          imageUrl: imagePath,
          videoUrl: completedVideos.join(',')
        }
      }
    })
    setRealtimeResults(cur => ({
      ...cur || {},
      [part]: "pending"
    }) as any);

    await updateLivenessAttempt({
      livenessId,
      stage: actionState || "front",
      parts: {
        [part]: {
          imageUrl: imagePath,
          videoUrl: completedVideos.join(','),
          browserDetails: {
            _ver: 2,
            codec: codec.current,
            userAgent: navigator.userAgent,
            hardwareConcurrency: navigator.hardwareConcurrency,
            deviceMemory: (navigator as any).deviceMemory,
            language: navigator.language,
            languages: navigator.languages,
            debugOptions: {
              shouldFail: !!shouldFail[part],
              log: logger.current
            }
          }
        }
      }
    });
  }

  useEffect(() => {
    if (actionState !== 'done') return;
    (async () => { 
      await updateLivenessAttempt({
        livenessId,
        stage: actionState
      });
      setCanCloseWindow(true);
    })();
  }, [livenessId, actionState]);

  const debugFrame = false;

  const backContent = useMarkdown(
    livenessConfig?.identification?.back?.content?.[context.lang] 
      || L.questions.identity.take_a_photo_of_back,
    false, // collapsible
    true // no paragraphs
  );

  if (!livenessConfig) return <SpacedSpinner />

  return (<>
    {['waiting','done'].includes(actionState || '') && <FinalPage
      canCloseWindow={canCloseWindow}
      livenessConfig={livenessConfig} 
      realtimeResults={realtimeResults} 
      setRealtimeResults={setRealtimeResults}
      setActionState={setActionState} 
      setRetrying={setRetrying} 
      setFacingMode={setFacingMode}
      multipleCameras={multipleCameras}
      livenessState={livenessState} />}
    <div className={"bg-gray-50 flex flex-col fixed h-full w-screen " + (["done","waiting"].includes(actionState as any) ? 'hidden' : '')} style={{height: '-webkit-fill-available'}}>
      {/* Top Nav Bar */}
      <div className="flex flex-none">
        <div className="flex-grow text-center self-center text-xs flex justify-center items-center" style={{height: '50px'}}>
          {{
            'front': livenessConfig?.identification?.front.content || { [context.lang]: L.questions.identity.take_a_photo_of_front },
            'front-captured': { [context.lang]: L.questions.identity.front_readable },
            'back': { [context.lang]: backContent },
            'back-captured': { [context.lang]: L.questions.identity.back_readable },
            'selfie': livenessConfig?.selfie.content || { [context.lang]: L.questions.identity.take_a_photo_of_face },
            'selfie-captured': { [context.lang]: L.questions.identity.selfie_readable },
            'waiting': { [context.lang]: L.questions.identity.please_wait_processing },
            'done': { [context.lang]: 
                canCloseWindow ? L.applicant.all_done_you_may_now_close_this_window : L.questions.identity.please_wait_processing },
          }[actionState || 'front']?.[context.lang]}
        </div>
      </div>

      {/* Error States */}
      <div style={{display: cameraAvailable ? 'none' : ''}}>
        <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
          <div className="p-6 bg-red-500 rounded-lg shadow-md max-w-sm">
            <h1 className="text-white font-bold text-xl mb-4">{L.selfie.camera_error}</h1>
            <p className="text-white">
              {L.selfie.camera_disabled}
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-gray-50 flex flex-grow items-stretch flex-column items-center overflow-hidden" style={{display: (!cameraAvailable || [null, "done"].includes(actionState)) ? 'none' : ''}}>
        <div className="flex h-full relative">
          {!capturing && actionState?.includes('-captured') && currentImage && <div className="h-full w-full items-center">
            {!videoMode && <img className="h-full w-full" style={{objectFit: 'contain'}} src={URL.createObjectURL(currentImage)} />}
            {videoMode && currentVideo && 
              <ul>
                <Attachment 
                  style={{objectFit: 'contain', height: '50%', width: '50%', margin: '0px auto' }} 
                  url={currentVideo} key={currentVideo} Viewer={"applicant"} 
                  autoPlay
                  playsInline muted />
              </ul>}
          </div>}
          {!captureReady && !currentImage && <div style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 100,
            backgroundColor: 'lightgray'
          }}></div>}
          <video className={`w-full ${currentImage ? 'hidden' : ''}`}
            style={{background: "#f2f2f2", transform: `${(facingMode === 'user' || !multipleCameras) ? 'scaleX(-1)' : ''} ${actionState === 'back' ? `scale(${backZoom})` : ''}`}} 
            ref={videoRef} playsInline muted />
          <div style={{ 
            position: 'absolute', 
            display: 'flex', alignItems: 'center',
            ...videoDimensions
          }} >
            {actionState === 'front' && idType === 'wide' && <CardFront style={{margin: 'auto'}} className="block flex-1 w-full h-full" />}
            {actionState === 'back' && (
              livenessConfig?.identification?.back?.alternativeDocument?.kind === 'Debit Card' 
                ? <DebitCardFront style={{margin: 'auto'}} className="block flex-1 w-full h-full" />
                : <CardBack style={{margin: 'auto'}} className="block flex-1 w-full h-full" />)}
            {actionState === 'selfie' && <Selfie style={{margin: 'auto'}} className="block flex-1 w-full h-full" />}
          </div>
          {livenessConfig?.identification?.front?.allowTallImages ? <div className="fixed w-50 bg-white m-auto justify-content-center top-15 z-20 left-1/2 transform -translate-x-1/2 flex space-x-0 rounded">
            {/**<!-- wide/Classic ID Style --> */}
            <button className="w-16 h-16 flex items-center justify-center bg-gray-50 rounded-l-md hover:bg-gray-100" onClick={() => setIDType("wide")}>
              <svg className="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="6" width="18" height="12" rx="2" ry="2"></rect>
              </svg>
            </button>
            {/**<!-- tall/Paper ID Style --> */}
            <button className="w-16 h-16 flex items-center justify-center bg-gray-50 rounded-r-md hover:bg-gray-100" onClick={() => setIDType("tall")}>
              <svg className="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="1" width="16" height="22" rx="2" ry="2"></rect>
              </svg>
            </button>
          </div> : null}
          <div className={debugFrame ? "bg-red-100" : "bg-white"} style={{
            position: 'absolute',
            top: ((maskDimensions?.top || 0) + (maskDimensions?.height || 0)) - 2,
            left: 0,
            width: '100%',
            height: '100%',
          }}></div>
          <div className={debugFrame ? "bg-blue-100" : "bg-white"} style={{
            position: 'absolute',
            top: 0,
            left: ((maskDimensions?.left || 0) + (maskDimensions?.width || 0)) - 2,
            width: '100%',
            height: '100%',
          }}></div>
          <div className={debugFrame ? "bg-yellow-100" : "bg-white"} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: (maskDimensions?.top || 0) + 2,
          }}></div>
          <div className={debugFrame ? "bg-green-100" : "bg-white"} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: (maskDimensions?.left || 0) + 2,
            height: '100%',
          }}></div>
        </div>
      </div>

      {/* Loading Spinner for Camera Recording */}
      <div className="w-100 flex-none bg-white flex flex-column items-center justify-center h-10">
        {(['front','back','selfie'].includes(actionState + '') && !captureReady) ? 
          <div style={{ zIndex: 100 }} 
            className="mx-auto my-4 justify-center flex items-center px-4 py-2 text-xs font-medium text-gray-700">
            <SpacedSpinner />
            <span>{L.selfie.starting_camera}</span>
          </div>
          : null}
      </div>

      {/* Debug options */}
      {process.env.NODE_ENV === 'development' && ['front','back','selfie'].includes(actionState + '') && <div className="fixed hover top-5 left-5 z-100 bg-white">
        <div className="">
          <label htmlFor="shouldfail"><input id="shouldfail" type="checkbox" checked={shouldFail[actionState as 'front']}
            onChange={(e) => setShouldFail(cur => ({
              ...cur,
              [actionState as 'front']: e.target.checked
            }))}/> Should {actionState} fail?</label>
        </div>
      </div>}

      {/* Bottom Nav Bar */}
      <div className="flex flex-none items-center justify-center">
        {['front','back','selfie'].includes(actionState ?? '') ?
          <div className="flex justify-center flex-1 text-center p-1" style={{ height: '60px' }} >
            <div className="flex-1">{multipleCameras &&
                <WhiteButton onClick={swapCamera}>
                  <ArrowPathIcon className="text-gray-800 mr-2" width={30} height={30} />
                  {L.selfie.switch_camera}
                </WhiteButton>}</div>
            <div className="flex-1">
              {captureReady ? <Button 
                onClick={async () => { 
                  await capture(actionState as 'front' | 'back' | 'selfie'); 
                }}
                className={`ml-2`}
                disabled={capturing}>
                {capturing ? <SpacedSpinner /> : <CameraIcon className="mr-2" width={30} height={30} />}
                <span>{L.selfie.take_photo}</span>
              </Button> : null}
            </div>
          </div>
          : null}
        {['front-captured','back-captured','selfie-captured'].includes(actionState ?? '') ? 
          <div className="flex-1 max-w-md items-center justify-center flex text-center p-1" style={{height: '60px'}}>
            <Button className="flex-1 text-center" onClick={() => {
              setActionState((cur) => {
                let next = cur!.replace("-captured", "") as "front" | "selfie" | "back";
                if (multipleCameras && next in facingModeForStage) {
                  setFacingMode((facingModeForStage as any)[next])
                }
                return next;
              });
              setCurrentImage(null);
            }}>
              <ArrowPathIcon className="mr-2" width={30} height={30} />
              {L.apply.previous}</Button>
            <Button className="flex-1 text-center" onClick={async () => {
              setCurrentImage(null);
              setActionState((cur) => { 
                let next = getNextActionState(cur) as any;
                if (multipleCameras && next in facingModeForStage) { 
                  setFacingMode((facingModeForStage as any)[next])
                }
                return next;
              });
            }}>
              <CheckCircleIcon className="mr-2" width={30} height={30} />
              {L.apply.next}
            </Button>
          </div>
          : null}
      </div>
    </div>
  </>
  );
}