import { PaymentStatusTable } from '@aidkitorg/component-library';
import { CellRenderer, CellRendererProps } from '@aidkitorg/component-library/dist/types/src/PaymentStatusTable';
import * as v0 from '@aidkitorg/types/lib/survey';
import { useMemo } from 'react';
import { useLocalizedStrings } from '../Localization';
import { snakeToEnglish } from '../Util';
import ExploreLink, { DistroExprLink } from './ExploreLink';

export default function PaymentDashboard({ component, results }: { component: v0.PaymentDashboard } & { results?: any[] }) {

  const L = useLocalizedStrings();
  const dimensions = component.dimensions ?? ['payment'];

  if (!results?.length) {
    return <div>No results to show!</div>
  }

  const rows = useMemo(() => results.map(r => ({
    dimensions: Object.assign(
      Object.fromEntries(dimensions.map(d => [d, component.friendlyDimensionNames ? snakeToEnglish(r.info[d]) : r.info[d]])),
      { payment: component.friendlyDimensionNames ? snakeToEnglish(r.payment) : r.payment }
    ),
    statuses: r.statuses
  })), [results]);


  function drillDownCell({ dimensions, status, value }: CellRendererProps) {
    const base: string[] = ['legal_name', dimensions.payment].concat(Object.keys(dimensions).filter(d => d !== 'payment'))

    if (status === 'failed' || status === 'cancelled') {
      base.push(...['unresolved_failure', 'failure', 'failure_type', 'failure_date'].map(suffix => [dimensions.payment, suffix].join('_')));
    }

    const paymentFields = ["status", "type", "amount", "date created", "transaction ref"]
      .map(paymentField => ({
        kind: 'Field',
        field: dimensions.payment,
        lookup: {
          kind: 'Payment',
          prop: paymentField
        }
      }) as v0.Field);

    return <div className="group">
      <span className="group-hover:hidden">{value}</span>
      <div className="hidden group-hover:block">
        <ExploreLink query={{
          query: {
            kind: 'Applicant Table',
            columns: base.map(field => ({ kind: 'Field', field }) as v0.Field).concat(paymentFields),
            filter: {
              kind: 'Click',
              expr: {
                kind: 'And',
                clauses: [
                  {
                    kind: 'Exists',
                    field: dimensions.payment
                  },
                  ...Object.entries(dimensions).filter(([k]) => k !== 'payment').map(([name, dim]) => ({
                    kind: 'Equals',
                    field: name,
                    value: dim
                  }) as v0.FieldEquals),
                  {
                    kind: 'Value Equals',
                    equals: {
                      kind: 'StringValue',
                      value: status.toUpperCase()
                    },
                    value: {
                      kind: 'Field',
                      field: dimensions.payment,
                      lookup: {
                        kind: 'Payment',
                        prop: 'status'
                      }
                    }
                  }
                ]
              }
            }
          }
        }} />
      </div>
    </div>;
  }

  return <>
    <PaymentStatusTable
      cellRenderer={drillDownCell}
      translations={{
        total: 'Total',
        payment: 'Payment Names',
      }}
      data={rows}
      sort={['desc', 'asc']}
      dimensions={dimensions as [string, string]}
    />
  </>;
}
