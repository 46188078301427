import { Button, Checkbox, Heading, Icon, InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot, Paragraph, PasswordForm, useToast } from "@aidkitorg/component-library";
import * as Sentry from "@sentry/react";
import React, { useContext, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { usePost } from "./API";
import { LanguageDropdown } from "./Components/LanguageDropdown";
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext, UserInfoContext } from "./Context";
import { useLocalizedStrings } from "./Localization";
import { AidKitLogo, classNames, useMarkdown } from "./Util";
import { Token } from "aidkit/lib/common/tokens";
import { ConsoleLogger } from "amazon-chime-sdk-js";

type LoginDisplayMode = 'EmailOnly' | 'VerifyingCode' | 'EnrollPassword' | 'VerifyPassword' | 'ForgotPassword' | 'ForgotPasswordConfirmation' | 'Blocked';

const AuthPage: React.FC<{ authStrategy?: 'session' | 'user' }> = ({ authStrategy = 'user' }) => {
  const { toast } = useToast();
  const userContext = useContext(UserInfoContext);
  const context = useContext(InterfaceContext);
  const configuration = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const history = useHistory();
  const L = useLocalizedStrings();
  const [, setCookie,] = useCookies(["auth_token"]);

  // Default login API routes
  const sendVerification = usePost("/authenticate");
  const verifyCode = usePost("/authenticate/complete", {
    token: () => verificationToken.current
  });

  // Session-based token "Modern Auth" API routes for 'session' authStrategy
  const sendConfirmation = usePost("/confirm/phone");
  const login = usePost("/login");

  // Session-based token (only for /signin path) for 'session' authStrategy
  const [token, setToken] = React.useState<string>('');

  // Control for login display
  const [loginDisplayMode, setLoginDisplayMode] = useState<LoginDisplayMode>('EmailOnly')

  // Password two-factor Auth
  const enrollPassword = usePost("/authenticate/password/enroll", {
    token: () => verificationToken.current
  })
  const verifyPassword = usePost("/authenticate/verify_password", {
    token: () => verificationToken.current
  })
  const requestResetPassword = usePost("/authenticate/password/forgot", {
    token: () => verificationToken.current
  });
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const [code, setCode] = useState<string>("");
  const [verifyingCode, setVerifyingCode] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<boolean>(false);

  const [emailFormData, setEmailFormData] = useState<{ email: string, emailOnly: boolean }>({ email: '', emailOnly: false });
  const [loading, setLoading] = useState<boolean>(false);
  const verificationToken = useRef("");
  const toastDismissRef = useRef<(() => void) | null>(null);

  const wasIdle = location.search.includes("&reason=idle")
  // Use RegEx to remove the reason=idle flag
  const next = decodeURIComponent(location.search.slice("?next=".length)).replace(new RegExp('&reason=idle$'), '');

  const resetOTPCode = () => {
    setLoginDisplayMode('EmailOnly')
    setVerifyingCode(false);
    setCodeError(false);
    setCode("");

    if (toastDismissRef.current) {
      toastDismissRef.current();
    }
  }

  const onCodeChange = (newValue: string) => {
    setCode(newValue);
    setCodeError(false);
  }

  const handleEmailFormSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const form = e.target as HTMLFormElement;
    // Let HTML5 validation kick in within the browser and don't do anything else if checkValidity is false
    if (form.checkValidity()) {
      // Session-based auth will send a OTP code to email only and will use the `token` state for the handshake
      if (authStrategy === 'session') {
        const result = await sendConfirmation({ phone: emailFormData.email, language: 'en' });
        setLoading(false);

        if (result.token) {
          setToken(result.token);
          setLoginDisplayMode('VerifyingCode')
        } else {
          toast({
            description: 'Error authenticating',
            variant: 'error'
          })
        }
      } else {
        // Regular auth path
        const result = await sendVerification({ email: emailFormData.email, email_only: emailFormData.emailOnly, next: next });
        setLoading(false);

        // Google SSO Redirection
        if (result?.redirect) {
          if (!result.oAuthStateToken) {
            // Missing OAuth state token from server
            toast({
              description: 'Error authenticating SSO',
              variant: 'error'
            })
            return;
          } else {
            // Temporarily stores OAuth state token to compare later when callback is received
            sessionStorage.setItem('oauth_state_token', result.oAuthStateToken);
            window.location.href = result.redirect;
            return;
          }
        }

        if (result?.popup) {
          const { dismiss } = toast({
            description: result.popup,
            action: <LoginCode code={result.popup} dismiss={() => { dismiss() }} />,
            autoClose: false,
          })
          toastDismissRef.current = dismiss;
        }

        verificationToken.current = result.intermediate_token;

        setLoginDisplayMode('VerifyingCode')
      }
    } else {
      setLoading(false);
      return;
    }
  }

  const handleAuthenticatedUser = (token: Token) => {
    // Create auth token that self-expires after 86400 seconds (~1 rotation of the earth -- 24 hours)
    setCookie("auth_token", token, {
      maxAge: 86400, 
      path: "/",
      sameSite: "strict"
    });

    // Causes the UserInfoWrapper which provides UserInfoContext to call /user/info again now that we have a verified token
    if (userContext?.refreshContext) {
      userContext.refreshContext();
    } else {
      Sentry.captureMessage('Could not refresh UserInfoContext, callback missing', 'warning');
    }

    // Dismiss temp local code toast if it exists
    if (toastDismissRef.current) {
      toastDismissRef.current();
    }

    // Find the place they came in on.
    history.push(next || '/');
  }

  const handleCodeSubmission = async (code: string) => {
    setVerifyingCode(true);

    if (authStrategy === 'session') {
      // TODO: admin passwords for session based auth

      const result = await login({ token, answer: code });
      setVerifyingCode(false);

      if (result?.token) {
        setCookie("auth_token", result.token, {
          maxAge: 86400, 
          path: "/",
          sameSite: "strict"
        });

        history.push(next || '/');
      } else if (result?.error && result.error === 'challenge_incorrect') {
        setCodeError(true);
        return;
      } else {
        // Return to home and let the API toast errors
        resetOTPCode();
        return;
      }
    } else {
      const result = await verifyCode({
        code: code
      });

      setVerifyingCode(false);

      if (result?.error) {
        if (result.error === 'incorrect_code') {
          setCodeError(true);
          return;
        } else {
          // Reset state if verification failed
          verificationToken.current = '';
          setLoginDisplayMode('EmailOnly')
          setCode('');
          return;
        }
      } else {
        if (publicConfig.adminPasswords && result.intermediate_token) {
          // Update intermediate token
          verificationToken.current = result.intermediate_token;

          if (result.require_new_password) {
            setLoginDisplayMode('EnrollPassword')
          } else {
            setLoginDisplayMode('VerifyPassword')
          }
        } else {
          handleAuthenticatedUser(result.auth_token)
        }
      }
    }
  }

  const handleEnrollPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)

    const result = await enrollPassword({
      password
    })

    if (result?.error) {
      setLoading(false)
      setPassword("")
      setPasswordConfirmation("")

      console.error(result.error)

      return;
    } else {
      setLoading(false) 
      handleAuthenticatedUser(result.auth_token)
    }
  }

  const handleVerifyPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)
    const result = await verifyPassword({
      password
    })

    if (result?.error) {
      setLoading(false)
      if (result.error == 'incorrect_password') {
        setPasswordError(true)
      } else {
        console.error(result.error)
      }
    } else {
      setLoading(false)
      handleAuthenticatedUser(result.auth_token)
    }
  }

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    setLoading(true);
    const response = await requestResetPassword({});

    if (response) {
      // Reset the login state once we've declared that we've forgotten our password and gotten a reset email
      setCode("");
      setEmailFormData({ ...emailFormData, email: '' })
      verificationToken.current = ''

      if (response.status === 'ok') {
        setLoginDisplayMode('ForgotPasswordConfirmation')
      }
      if (response.error === 'account_temporary_locked') {
        setLoginDisplayMode('Blocked')
      }
    }

    setLoading(false);
  }

  const AnnouncementBanner = () => {
    const banner = (message: string, color?: 'Red' | 'Yellow' | 'Blue') => {
      const colorConfig: Record<'Red' | 'Yellow' | 'Blue', { bg: string, text: string }> = {
        'Red': { bg: 'bg-red-700', text: 'text-rose-50' },
        'Yellow': { bg: 'bg-yellow-400', text: 'text-amber-950' },
        'Blue': { bg: 'bg-indigo-800', text: 'text-indigo-50' }
      }

      return (
        <div className={`${colorConfig[color ?? 'Blue'].bg} px-6 py-2 flex justify-center`}>
          <Paragraph className={`${colorConfig[color ?? 'Blue'].text} text-sm text-center m-0`} measure="wide">
            {useMarkdown(message)}
          </Paragraph>
        </div>
      );
    }

    if (configuration.announcement) {
      // Prioritize legacy airtable announcement
      return banner(configuration.announcement);
    } else if (wasIdle) {
      // Prioritize inactivity logout banner
      return banner(L.login.idle_logout, 'Blue')
    } else if (context.loginBanner) {
      // Fetch new interface announcement
      return banner(context.loginBanner[context.lang], context.loginBanner.color as 'Red' | 'Yellow' | 'Blue');
    }

    // Return null if no announcement banner
    return null;
  };

  // NOTE: This is only used in development
  const LoginCode: React.FC<{ code: any, dismiss: () => void }> = ({ code, dismiss }) => {
    const [copied, setCopied] = useState<boolean>(false);
    const handleCopy = () => {
      navigator.clipboard.writeText(code);
      setCopied(true);
    }

    return (
      <div className="flex flex-row gap-2 min-w-[225px]">
        <Button onClick={handleCopy} icon={<Icon name="DocumentDuplicateIcon" />}>Copy Code</Button>
        {copied && <small className="flex flex-row gap-1 items-center"><Icon name="CheckCircleIcon" color="#15803d" type="solid" /> Copied</small>}
      </div>
    )
  }

  const EmailLogin = (
    <form onSubmit={handleEmailFormSubmission} className="flex flex-col min-h-[160px]">
      <div className="flex-grow w-full space-y-2 py-1">
        <label htmlFor="email" className="block text-sm font-medium">
          {L.auth.email_address}
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            value={emailFormData.email}
            onChange={(e) => setEmailFormData({ ...emailFormData, email: e.target.value })}
            required
            autoComplete="email"
            className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 text-sm md:text-base"
            autoFocus
          />
        </div>
        {/* Email-only option is only available on regular auth (not session-based auth) */}
        {authStrategy !== 'session' && (
          <div className="flex items-center">
            <Checkbox
              label={L.auth.send_code_to_email}
              checked={emailFormData.emailOnly}
              onChange={(checked) => setEmailFormData({ ...emailFormData, emailOnly: checked as boolean })}
            />
          </div>
        )}
      </div>
      <div className="flex-grow w-full flex items-end py-1">
        <Button type="submit" variant="primary" className="w-full" loading={loading}>
          {L.auth.submit}
        </Button>
      </div>
    </form>
  );

  const OTPVerify = (
    <div className="flex flex-col min-h-[160px]">
      <div className="flex-grow w-full py-1">
        {verifyingCode && <div className="flex justify-center items-center min-h-[6em]"><div className="w-6 h-6 border-2 border-t-transparent border-blue-200 rounded-full animate-spin mr-2" /></div>}

        {!verifyingCode && (
          <>
            <div className="flex justify-center">
              <InputOTP maxLength={6} autoFocus label={L.auth.verification_code} onComplete={handleCodeSubmission} error={codeError} value={code} onChange={onCodeChange}>
                <InputOTPGroup>
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                </InputOTPGroup>
                <InputOTPSeparator />
                <InputOTPGroup>
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                </InputOTPGroup>
              </InputOTP>
            </div>
            <Paragraph size="sm" className="my-3">
              {(emailFormData.emailOnly
                ? L.auth.code_sent_to_email
                : L.auth.code_sent_to_phone_or_email
              ).replace('$email', emailFormData.email)}
            </Paragraph>
          </>)
        }
      </div>
      <div className="flex-grow flex items-end py-1">
        <Button type="submit" variant="secondary" className="w-full" onClick={resetOTPCode}>
          {L.auth.back}
        </Button>
      </div>
    </div>
  );

  const enrollPasswordLogin = () => {
    // Validation rules should match with those in packages/aidkit/lib/authentication/passwords.ts
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>~`_\+\-=\[\]\\;]/;
  
    const specialCharLabel = (
      <div>
        <p className="mb-0">{L.auth.validation_rules.special_char}</p>
        <p>{'[!@#$%^&*(),.?":{}|<>~`_\+\-=\[\]\\;]'}</p>
      </div>
    )
  
    const validationRules =  [
      { regex: /.{16,}/, label: L.auth.validation_rules.at_least_16_chars},
      { regex: /^.{1,63}$/, label: L.auth.validation_rules.less_than_64_chars},
      { regex: uppercaseRegex, label: L.auth.validation_rules.uppercase},
      { regex: lowercaseRegex, label: L.auth.validation_rules.lowercase},
      { regex: numberRegex, label: L.auth.validation_rules.number},
      { regex: specialCharRegex, label: specialCharLabel},
    ]

    return (
      <div className="flex flex-col w-full min-h-[160px]">
        <p>
          {L.auth.password_notice}
        </p>
        <PasswordForm 
          value={password}
          onValueChange={(e) => setPassword(e.target.value)}
          confirmationValue={passwordConfirmation}
          onConfirmationValueChange={(e) => setPasswordConfirmation(e.target.value)}
          validationRules={validationRules}
          loading={loading}
          onSubmit={handleEnrollPassword}
        />
      </div>
    )
  }

  const passwordErrorMsg = (
    <div className="py-2 flex gap-1 items-center">
      <div>
        <Icon role="decorative" name="XCircleIcon" type="solid" className="text-red-500"/>
      </div>
      <div className="text-sm">
        {L.auth.incorrect_password}
      </div>
    </div>
  )

  const verifyPasswordLogin = (
    <form onSubmit={handleVerifyPassword} className="flex flex-col min-h-[160px]">
      <div className="flex flex-col min-h-[160px]">
        <div className="flex-grow w-full space-y-2 py-1">
          <div className="block text-sm font-medium">
            {L.auth.email_address}
          </div>
          <div className="mt-2">
            {emailFormData.email}
          </div>
          <label htmlFor="password" className="block text-sm font-medium">
            {L.auth.password}
          </label>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={classNames(
                "block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6",
                passwordError ? "outline-red-500" : "outline-gray-300",
              )}
              autoFocus
            />
            {passwordError && passwordErrorMsg}
          </div>
        </div>
        <div className="flex-grow flex items-end py-2">
          <Button type="submit" variant="primary" className="w-full" loading={loading}>
            {L.auth.submit}
          </Button>
        </div>
        <div className="pt-2">
          <Button variant="link" onClick={() => {
            setLoginDisplayMode('ForgotPassword')
          }}>
            {L.auth.forgot_password}
          </Button>
        </div>
      </div>
    </form>
  )

  const forgotPassword = (
    <form onSubmit={handleForgotPassword}>
      <Heading level="h3" className="text-center mb-3">
        {L.auth.forgot_password}
      </Heading>
      <Paragraph className="mt-0 mb-1.5">
        {L.auth.forgot_password_prompt}
      </Paragraph>
      <div className="flex-grow w-full space-y-2 py-1">
        <span>{emailFormData.email}</span>
      </div>
      <div className="flex-grow flex items-end pb-2 pt-4">
        <Button type="submit" variant="primary" className="w-full" loading={loading}>
          {L.auth.forgot_password_send}
        </Button>
      </div>
    </form>
  )

  const forgotPasswordConfirmation = (
    <div>
      <Icon name="CheckCircleIcon" role="decorative" size="xl" type="solid" className="mx-auto text-green-800 mb-2" />
      <Heading level="h3" className="text-center mb-3">
        {L.auth.email_sent}
      </Heading>
      <Paragraph className="mt-0">
        {L.auth.email_sent_message}
      </Paragraph>
      <Button variant="primary" className="w-full" onClick={() => {setLoginDisplayMode('EmailOnly')}}>
        {L.auth.return_to_login}
      </Button>
    </div>
  );

  const blocked = (
    <div>
      <Icon name="ExclamationTriangleIcon" role="decorative" size="xl" type="solid" className="mx-auto text-amber-800" />
      <Heading level="h3" className="text-center mb-3">
        {L.auth.reset_blocked_title}
      </Heading>
      <Paragraph className="mt-0">
        {L.auth.reset_blocked_message}
      </Paragraph>
      <Button variant="primary" className="w-full" onClick={() => { setLoginDisplayMode('EmailOnly') }}>
        {L.auth.return_to_login}
      </Button>
    </div>
  )

  const loginDisplay = () => {
    switch (loginDisplayMode) {
      case 'EmailOnly': 
        return EmailLogin;
      case 'VerifyingCode':
        return OTPVerify;
      case 'EnrollPassword':
        return enrollPasswordLogin();
      case 'VerifyPassword':
        return verifyPasswordLogin;
      case 'ForgotPassword':
        return forgotPassword;
      case 'ForgotPasswordConfirmation':
        return forgotPasswordConfirmation;
      case 'Blocked':
        return blocked;
    }
  }

  const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configuration.applicant_facing_logo;
  const programName = publicConfig.name || configuration.program_name || 'AidKit Program';

  return (
    <div className="min-h-screen bg-gray-100 min-w-[180px]">
      <AnnouncementBanner />
      <div className="relative flex flex-col items-center md:flex-row md:items-start md:justify-center pb-2 px-2 md:pt-[12vh]">
        <div className="my-[1rem] md:my-0 md:absolute md:top-4 md:left-4">
          <LanguageDropdown languages={(configuration.languages || 'en,es').split(',')} />
        </div>
        <div className="w-full max-w-sm">
          <div className="bg-slate-800 px-6 py-2 rounded-t-lg border-l border-r border-t border-gray-300">
            <Paragraph size='sm' className="m-0 text-slate-200 text-center font-medium uppercase">
              {L.auth.admin_only}
            </Paragraph>
          </div>
          <div className="bg-white px-4 py-6 border-l border-r border-gray-300">
            <div className="mx-auto w-full">
              <div className="flex flex-col align-middle items-center mt-2 mb-4">
                {applicantFacingLogo ? (
                  <img
                    className="w-auto max-w-[180px] max-h-[100px]"
                    src={applicantFacingLogo}
                    alt={programName}
                  />
                ) : (
                  <AidKitLogo width={180} height={60} />
                )}
              </div>
              {['EmailOnly', 'VerifyingCode', 'EnrollPassword', 'VerifyPassword'].includes(loginDisplayMode) && (
                <>
                  <Heading level="h3" className="text-center mb-1">
                    {L.auth.admin_login}
                  </Heading>
                  <Paragraph className="text-center mt-0 font-medium text-gray-700">
                    {L.auth.please_authenticate_continue}
                  </Paragraph>
                </>
              )}
            </div>
            {loginDisplay()}
          </div>
          <div className="bg-slate-50 px-2 py-1 rounded-b-lg sm:px-6 sm:py-6 border-l border-r border-b border-gray-300">
            <footer>
              <Paragraph size='xs' className="mt-10 text-gray-500 px-3">
                {L.auth.we_use_cookies}
              </Paragraph>
              <ul className="px-3 text-sm flex space-x-5">
                <li><Button variant="link" href="/privacy.html">{L.privacy_policy}</Button></li>
                <li><Button variant="link" href="/tos.html">{L.terms_and_conditions}</Button></li>
              </ul>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthPage;
