import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import InterfaceContext, { ConfigurationContext } from "../Context";
import { languageContent, safeParse } from "../Util";
import { QuestionProps } from "./Props";
import html2canvas from "html2canvas";
import { useAPIUpload, usePost } from "../API";
import { useLocalizedStrings } from "../Localization";
import { Button, Switch } from "@aidkitorg/component-library";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import * as v0 from "@aidkitorg/types/lib/survey";
import { cropCanvasToDataURL } from "../CanvasUtil";

type extraProps = {
  // Special prop for InlineSignature
  // Allows replacing a string in the inline signature with a custom element
  // Used by the W9 Form to replace #checkbox with a checkbox to cross out line 2.
  replaceWithHtml?: {
    regex: RegExp,
    html: string
  }
}

export function InlineSignature(props: QuestionProps & extraProps) {
  const context = useContext(InterfaceContext);
  const content = props[languageContent(context.lang)]?.replace("\\(", "(").replace("\\[", "[") || '';
  const signatureCanvas = useRef(null as HTMLCanvasElement | null);
  const baseCanvas = useRef(null as HTMLCanvasElement | null);

  const contract = useRef(null as HTMLDivElement | null);
  const controls = useRef(null as HTMLDivElement | null);
  const signatureInstructionsRef = useRef(null as HTMLDivElement | null);
  const confirmInstructionsRef = useRef(null as HTMLDivElement | null);
  const confirmedSigRef = useRef(null as HTMLDivElement | null);
  const typeSignatureButtonRef = useRef(null as HTMLDivElement | null);
  const saveTypeSignatureButtonRef = useRef(null as HTMLDivElement | null);
  const loadSignatureBoxRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [typedSignature, setTypedSignature] = useState('');
  const [isDrawingMode, setIsDrawingMode] = useState(true);
  const [signedImage, setSignedImage] = useState(props.info[props['Target Field']!] as string | null);
  const [confirmed, setConfirmed] = useState(!!(props['Target Field'] && props.info[props['Target Field']]));
  const [signatureToSave, setSignatureToSave] = useState("");
  const certifyContract = usePost("/document/certify");
  const [saving, setSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const config = useContext(ConfigurationContext);
  const metadata = safeParse(props.Metadata || '{}');
  const hasConfirmStep = metadata?.confirmButton;

  const upload = useAPIUpload("/upload", (progress: number) => {
    setProgress(Math.round(progress * 100));
  });

  const L = useLocalizedStrings();
  
  useEffect(() => {
    if (!props['Target Field'] || !props.info) return;
    const targetFieldValue = props.info?.[props['Target Field']!];
    // If a signature is deleted in one tab, the signature is also cleared in another open tab without requiring refresh.
    if (!targetFieldValue) {
      clearSignature();
    }
    setSignedImage(targetFieldValue as string | null);
  }, [props.info[props['Target Field']!]]);

  const [strokes, setStrokes] = useState(0);
  const loadSignatureBox = useCallback((node: HTMLInputElement) => {
    if (node) {
      const containerWidth = node.clientWidth;

      if (!signatureCanvas.current) {
        const base = document.createElement('canvas');
        base.className = "border rounded-md"
        base.style.touchAction = "none";
        base.style.position = 'absolute';
        base.style.left = '0';
        base.style.top = '0';
        base.style.zIndex = '0';
        baseCanvas.current = base;
        base.width = containerWidth - 2;
        base.height = 100;

        const sig = document.createElement('canvas');
        sig.className = "rounded-md";
        sig.style.touchAction = "none";
        signatureCanvas.current = sig;
        sig.style.backgroundColor = 'transparent';
        sig.style.position = 'absolute';
        // Offset a little to not interrupt the border of the base
        sig.style.left = '2px';
        sig.style.top = '2px';
        sig.style.zIndex = '1';
        sig.width = base.width - 2;
        sig.height = base.height - 2;

        if (isDrawingMode) {
          sig.classList.add("border-green-300", "ring-2", "ring-green-300", "ring-opacity-50");
        } 

        var isDrawing = false;
        var x = 0;
        var y = 0;
        var ctx = sig.getContext("2d")!;
        ctx.fillStyle = 'transparent';
        ctx.strokeStyle = "black";
        ctx.fillRect(0, 0, sig.width, sig.height);
        ctx.lineWidth = 1;
                
        var ctxBase = base.getContext("2d")!;
        ctxBase.fillStyle = 'white';
        ctxBase.fillRect(0, 0, base.width, base.height);
        ctxBase.beginPath();
        ctxBase.strokeStyle = "black";
        ctxBase.setLineDash([2,2]);
        ctxBase.lineWidth = 1;
        ctxBase.moveTo(20, 80);
        ctxBase.lineTo(containerWidth - 20, 80);
        ctxBase.stroke();
        ctxBase.closePath();

        const removeStrokeEffect = () => {
          if (signatureCanvas.current) {
            signatureCanvas.current.classList.remove("border-green-500", "ring-4", "ring-green-500", "ring-opacity-75");
          }
        };

        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {                    
          ctx.beginPath();
          ctx.strokeStyle = "black";
          ctx.lineWidth = 1;
          ctx.setLineDash([]);
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.stroke();
          ctx.closePath();
        }

        var mousedown = function(e: MouseEvent | TouchEvent) {
          e = e as MouseEvent;

          x = e.offsetX;
          y = e.offsetY;

          const t = e as unknown as TouchEvent
          if (t.touches) {
            const rect = sig.getBoundingClientRect();
            const top = rect.top + document.documentElement.scrollTop;
            const left = rect.left + document.documentElement.scrollLeft;
            x = t.touches[0].pageX - left;
            y = t.touches[0].pageY - top;
          }
          isDrawing = true;
        }
        var mousemove = function(e: MouseEvent | TouchEvent) {
          if (isDrawing === true) {
            setStrokes(strokes + 1);
            const t = e as unknown as TouchEvent
            if (t.touches) {
              const rect = sig.getBoundingClientRect();
              const top = rect.top + document.documentElement.scrollTop;
              const left = rect.left + document.documentElement.scrollLeft;
              var nx = t.touches[0].pageX - left;
              var ny = t.touches[0].pageY - top;
              drawLine(x, y, nx, ny);
              x = nx;
              y = ny;
            } else {
              e = e as MouseEvent;
              drawLine(x, y, e.offsetX, e.offsetY);
              x = e.offsetX;
              y = e.offsetY;
            }
          }
        };
        var mouseup = function(e: MouseEvent | TouchEvent) {
          document.body.style.backgroundColor = 'white';
          if (isDrawing === true) {
            const t = e as unknown as TouchEvent
            if (t.touches) {
              //var nx = e.touches[0].pageX - e.touches[0].target.offsetLeft;
              //var ny = e.touches[0].pageY - e.touches[0].target.offsetTop;
              //drawLine(x, y, nx, ny);
            } else {
              e = e as MouseEvent;
              drawLine(x, y, e.offsetX, e.offsetY);
            }
            x = 0;
            y = 0;
            isDrawing = false;
            removeStrokeEffect();
          }
        }
        sig.addEventListener('touchstart', mousedown);
        sig.addEventListener('mousedown', mousedown);
        sig.addEventListener('touchmove', mousemove);
        sig.addEventListener('mousemove', mousemove);
        sig.addEventListener('touchend', mouseup);
        sig.addEventListener('mouseup', mouseup);
        document.addEventListener('mouseup', function(e) { isDrawing = false; });
      }
      node.appendChild(baseCanvas.current!);
      node.appendChild(signatureCanvas.current);
    }
  }, [])

  function clearSignature() {
    setTypedSignature('');
    if (signatureCanvas.current) {
      const sig = signatureCanvas.current;
      var ctx = sig.getContext("2d")!;
      ctx.clearRect(0, 0, sig.width, sig.height);
      setStrokes(0);
    } 
    setSignedImage(null);
  }

  function typeSignature() {
    clearSignature();

    if (loadSignatureBoxRef.current) {
      loadSignatureBoxRef.current.style.display = "block";
    }

    if (signatureCanvas.current) {
      const sig = signatureCanvas.current;
      var ctx = sig.getContext("2d")!;
      ctx.setLineDash([]);
      ctx.font = "20px serif";
      ctx.strokeText('/' + typedSignature + '/', 20, 30);

      sign();
    } 
  }

  const handleTypeSignatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypedSignature(event.target.value);
  };

  function sign() {
    if (controls.current) controls.current!.style.visibility = "hidden";
    if (signatureInstructionsRef.current) signatureInstructionsRef.current.style.display = "none";
    if (typeSignatureButtonRef.current) typeSignatureButtonRef.current.style.display = "none";
    if (saveTypeSignatureButtonRef.current) saveTypeSignatureButtonRef.current.style.display = "none";

    if (!signatureCanvas.current) {
      console.error("signatureCanvas is not available in sign function");
      return;
    }

    let signerName = props.info['legal_name'];
    if (metadata && metadata.signer_name) {
      if (typeof metadata.signer_name === 'string') {
        signerName = props.info[metadata.signer_name];
      } else if (metadata.signer_name.special === 'Viewer Name') {
        if(config?.user?.name) {
          signerName = config.user.name;
        }
      }
    }

    setSaving(true);
    setProgress(0);
    let interval = setInterval(() => {
      setProgress(p => Math.min(100, p + 1))
    }, 100);
    
    setTimeout(async () => {
      await Promise.all([
        html2canvas(contract.current!, { 'scale': 1 }),
        html2canvas(signatureCanvas.current!, { 'scale': 1 })
      ]).then(async function([contractCanvas, signatureCanvas]) {
        // Crop the signature canvas
        let rawSignature = cropCanvasToDataURL(signatureCanvas);

        const response = await certifyContract({
          image: contractCanvas.toDataURL(), 
          legal_name: signerName,
          anonymous: metadata.anonymous,
          rawSignature
        });

        if (metadata.captureRawSignature) {
          if (response.rawSignature) {
            setSignatureToSave(response.rawSignature);
            if (!hasConfirmStep) {
              props.setInfoKey(props['Target Field']!, response.rawSignature, true, false);
            }
          } 
        } else {
          setSignatureToSave(response.image);
          if (!hasConfirmStep) {
            props.setInfoKey(props['Target Field']!, response.image, true, false);
          }
        }

        setSignedImage(response.image);
      }).finally(() => {
        clearInterval(interval);
        setProgress(100);
        setSaving(false);
      });
    }, 100);

    if (confirmInstructionsRef.current) {
      confirmInstructionsRef.current.classList.remove("hidden");
    }
  }

  const toggleSignatureMode = () => {
    setIsDrawingMode((prev) => !prev);
    clearSignature(); 
  };

  const handleConfirm = () => {
    if (signatureToSave) {
      props.setInfoKey(props['Target Field']!, signatureToSave, true, false);
      setConfirmed(true);
    } else {
      alert("No signature found. Please sign again.");
      clearSignature();
    }
  }

  async function deleteSignature() {
    const yn = await window.confirm(L.questions.inline_signature.delete_confirm);
    if (!yn) return;
    clearSignature();
    setSignedImage(null);
    setConfirmed(false);
    props.setInfoKey(props['Target Field']!, '', false, true);
  }

  const marked = useModularMarkdown({
    content: metadata.modernType 
      ? (metadata.modernType as v0.InlineSignature).content 
      : content,
    uid: props.uid,
    info: props.info,
    replaceWithHtml: props.replaceWithHtml
  })

  if (signedImage) {  
    return <div className="flex flex-column space-between">
      <div className="overflow-hidden" style={{ height: 'auto', width: 'auto' }}>
        <img src={signedImage} alt="User Signature"/>
      </div>
      <br/>

      {hasConfirmStep && !confirmed && signedImage && <div className="mt-4 flex justify-start space-x-2">
        <Button variant="secondary" size="md" aria-label="Clear signature" onClick={clearSignature} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {L.questions.textentry.edit}
        </Button>
        <Button variant="primary" size="md" onClick={handleConfirm} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-700 text-white text-sm font-medium hover:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          aria-describedby="saveInstruction"
        >
          {L.card.confirm} 
        </Button>
      </div>}

      <div className="-mt-5">
        {/* Only administrators can delete confirmed signatures. */}
        {(process.env.NODE_ENV === 'development' || (config.roles || '').includes('admin')) &&
        <div className="w-full">
          <Button variant="danger" size="md" onClick={async () => deleteSignature()} className='mt-8 float-right'>
            {L.questions.inline_signature.delete}
          </Button>
        </div>
        }
      </div>
    </div>
  }
  
  return <div className="relative">
    <div ref={contract} className="rounded-md mx-auto">
      <div className="my-2">
        {marked}
      </div>
      <div>
        <div ref={signatureInstructionsRef} className="rounded flex bg-gray-100 text-gray-800 active:bg-gray-200 focus-visible:bg-gray-200 px-3 py-3">
          <div id="signature_instructions"  className="flex flex-row m-1">
            <p className="font-semibold text-xs -mb-0.5">{L.questions.inline_signature.please_click_save}</p>
          </div>
        </div>
      </div>
      {!signedImage && <div className="mt-8">
        <div id="type_signature_button" ref={typeSignatureButtonRef} className="full-width flex flex-wrap justify-between items-center">
          <label className="mt-3 font-semibold text-gray-500">
            {isDrawingMode ? L.questions.inline_signature.draw_your_signature : L.questions.inline_signature.type_your_signature}
          </label>
          <div>
            <Switch label={L.questions.inline_signature.type_signature} checked={!isDrawingMode} onCheckedChange={toggleSignatureMode}/>
          </div>
        </div>

        {isDrawingMode ? (
          <>
            <fieldset className="mt-2">
              <div style={{height: '6.3125em'}}>
                <div style={{position: 'relative' }} ref={loadSignatureBox}></div>
              </div>
            </fieldset>
            <div ref={controls} className="mt-4 flex justify-start space-x-2">
              { strokes > 0 && (
                <div>
                  <Button variant="secondary" size="md" aria-label="Clear signature" onClick={clearSignature} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    {L.questions.inline_signature._clear}
                  </Button>
                  <Button variant="primary" size="md" onClick={sign} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-700 text-white text-sm font-medium hover:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    aria-describedby="saveInstruction"
                  >
                    {L.questions.inline_signature._save} 
                  </Button>
                </div> 
              )}
            </div>
          </>
        ) : (
          <div className="typing-signature">
            <div id="save_type_signature_button" ref={saveTypeSignatureButtonRef}>
              <input
                ref={inputRef}
                type="text"
                value={typedSignature}
                onChange={handleTypeSignatureChange}
                tabIndex={0}
                autoFocus={true}
                placeholder={L.questions.inline_signature.sign_here_and_save}
                className={`border rounded-md p-2 w-full mt-1 focus:ring-2 focus:ring-indigo-500 shadow-sm focus:ring-opacity-50 outline-none ${typedSignature ? 'bg-indigo-100' : ''}`}
              />
              {typedSignature && (
                <div className="mt-3 -ml-1">
                  <Button variant="secondary" size="md" aria-label="Clear signature" onClick={clearSignature} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    {L.questions.inline_signature._clear}
                  </Button>
                  <Button variant="primary" size="md" onClick={typeSignature} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-700 text-white text-sm font-medium hover:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    aria-describedby="saveInstruction"
                  >
                    {L.questions.inline_signature._save} 
                  </Button>
                </div> 
              )}
            </div>
            <div style={{height: '6.5em', display: 'none'}} ref={loadSignatureBoxRef}>
              <div style={{position: 'absolute'}} ref={loadSignatureBox}></div>
            </div>
          </div>
        )}
      </div>}

    </div>
    {/* Overlay Component */}
    {saving ?
      <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded shadow-lg text-center">
          <span className="text-lg font-bold">{L.questions.inline_signature._saving}</span>
          <span>{progress + "%"}</span>
        </div>
      </div> : null}
  </div>
}