import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocalizedStrings } from "../Localization";
import { ConfigurationContext } from "../Context";
import { usePost } from "../API";
import { SpacedSpinner, classNames } from "../Util";
import { Card, CardHeader, CardBody, CardFooter, Button, Icon, CodeDisplay } from "@aidkitorg/component-library";

type HistoryItemProps = {
  item: any;
  config: any;
  searchTerm: string;
};

function HistoryItemComponent(props: HistoryItemProps) {
  const L = useLocalizedStrings();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const popParentRef = useRef<HTMLDivElement | null>(null);
  const [prettyValue, setPrettyValue] = useState('');
  const [isJSON, setIsJSON] = useState(false);

  function highlightText(text?: string, searchTerm?: string): JSX.Element {
    if (!text) return <></>;
    if (!searchTerm) return <>{text}</>;
    const regex = new RegExp(`(${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, index) =>
          regex.test(part) ? <span key={index} className="bg-yellow-100">{part}</span> : part
        )}
      </>
    );
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      if (target && !target.closest('.metadata-popover') && !target.closest('.popover-button')) {
        setPopoverVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (popoverVisible && popoverRef.current && popParentRef.current) {
      const popover = popoverRef.current;
      const popParent = popParentRef.current;
      const popoverRect = popover.getBoundingClientRect();
      const parentRect = popParent.getBoundingClientRect();

      const offset = parentRect.left - parentRect.left;

      if (popoverRect.right > parentRect.right) {
        popover.style.right = `${offset - (popoverRect.right - parentRect.right) - 10}px`;
      } else if (popoverRect.left < parentRect.left) {
        popover.style.right = `${offset + (parentRect.left - popoverRect.left) + 10}px`;
      } else {
        popover.style.right = `${offset}px`;
      }
      popover.style.top = '-25px'
    }
  }, [popoverVisible]);


  useEffect(() => {
    try {
      if (isNaN(props.item.value)) {
        const parsed = JSON.stringify(JSON.parse(props.item.value), null, 2);
        setIsJSON(true)
        setPrettyValue(parsed);
      } else {
        setPrettyValue(props.item.value)
        setIsJSON(false)
      }
    } catch (e) {
      setIsJSON(false);
      setPrettyValue(props.item.value);
    }
  }, [props.item.value]);

  return (
    <Card className="p-3">
      <CardHeader
        title={<strong>{highlightText(props.item.key, props.searchTerm)}</strong>}
        badgeText={props.item.latest && L.applicant.history.latest}
        badgeVariant="success"
        actionButton={
          <Button
            className="popover-button px-0"
            variant="unstyled"
            icon={<Icon
              name="InformationCircleIcon"
              role="informative"
              title="Show metadata"
            />}
            onClick={() => { setPopoverVisible(!popoverVisible) }}
          >
          </Button>
        }
      />
      <CardBody className="py-2">
        <>
          <div ref={popParentRef} >
            {props.config?.roles?.includes('admin') && (
              <div className="relative">
                {popoverVisible && (
                  <div ref={popoverRef} className="absolute z-10 p-2 mt-2 w-72 bg-white border border-gray-200 rounded-md shadow-lg metadata-popover">
                    <div className="p-2 text-sm font-medium text-gray-700">
                      {L.campaigns.metadata}
                    </div>
                    <pre className="bg-gray-100 p-2 max-h-80 overflow-y-auto text-sm text-gray-700 text-monospace">
                      {props.item.metadata}
                    </pre>
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            <CodeDisplay
              className='border-0'
              variant="gray"
              contentMode="wrap"
              language={isJSON ? "json" : "text"}
              code={prettyValue}
              highlightText={props.searchTerm}
              copyable />
          </div>
        </>
      </CardBody>
      <CardFooter>
        <div className="text-sm flex justify-end flex-col items-end">
          <div>
            {highlightText(props.item.author_name, props.searchTerm)}
          </div>
          <div>
            {props.item.created_at}
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

type HistoryProps = { uid: string, canViewChanges: boolean };

export default function History(props: HistoryProps) {
  const L = useLocalizedStrings();
  const [searchTerm, setSearchTerm] = useState('');
  const [exactMatch, setExactMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isUpdating = useRef(false);
  const config = useContext(ConfigurationContext);
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [searchTargetField, setSearchTargetField] = useState(true);
  const [searchValue, setSearchValue] = useState(true);
  const [searchAuthor, setSearchAuthor] = useState(true);
  const [onlyShowLatest, setOnlyShowLatest] = useState(false);
  const [hideEmptyErrors, setHideEmptyErrors] = useState(true);
  const [visibleHistoryCount, setVisibleHistoryCount] = useState(50);

  const getHistory = usePost('/applicant/get_history');

  type HistoryItem = (Awaited<ReturnType<typeof getHistory>>['changes'])[number];

  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [filteredHistory, setFilteredHistory] = useState<HistoryItem[]>([]);

  const showMoreRef = useRef(null);
  const showLessRef = useRef(null);

  const loadHistory = useCallback(async () => {
    isUpdating.current = true;
    const fetchedHistory = await getHistory({ applicant: props.uid });
    if (!fetchedHistory || (fetchedHistory as any)?.error) {
      return;
    }
    setHistory(fetchedHistory.changes);
    setIsLoading(false);
    isUpdating.current = false;
  }, [getHistory, props.uid]);

  useEffect(() => {
    (async () => await loadHistory())()
    const intervalId = setInterval(loadHistory, 5000);
    return () => clearInterval(intervalId);
  }, [loadHistory]);

  useEffect(() => {
    if (history) {
      // This keeps track of fields which end in _error and have never had a non-empty value
      const alwaysEmptyErrors: Record<string, boolean> = {};
      let filtered = history.filter((historyItem) => {
        // Update the empty errors map, and we will use it to filter afterward in a second pass
        if (hideEmptyErrors && historyItem.key.endsWith('_error')) {
          if (historyItem.value) {
            alwaysEmptyErrors[historyItem.key] = false;
          } else {
            // Only overwrite undefined values
            alwaysEmptyErrors[historyItem.key] ??= true;
          }
        }

        const searchValues = [
          searchTargetField ? historyItem.key : null,
          searchValue ? historyItem.value : null,
          searchAuthor ? historyItem.author_name : null
        ].filter(v => v !== null) as string[];

        const searchMatch = !searchTerm.length || searchValues.some(v => {
          if (exactMatch) {
            return v === searchTerm;
          } else {
            return v.toLowerCase().includes(searchTerm.toLowerCase());
          }
        });
        const latestMatch = onlyShowLatest ? historyItem.latest : true;
        return searchMatch && latestMatch;
      });

      // If we want to hide always empty errors, then refilter with the map we constructed above.
      // !== true will cover undefined, and false
      if (hideEmptyErrors) {
        filtered = filtered.filter((historyItem) => alwaysEmptyErrors[historyItem.key] !== true);
      }
      setFilteredHistory(filtered);
    }
  }, [searchTerm, exactMatch, history, searchTargetField, searchValue, searchAuthor, onlyShowLatest, hideEmptyErrors]);

  useEffect(() => {
    // Show more ref to render more results when a user scrolls to the bottom of the list.
    // Show less ref to remove excess rendered results when a user returns to the search bar.
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isUpdating.current) {
          if (entry.target === showMoreRef.current) {
            setVisibleHistoryCount((prevCount) => prevCount + 50);
          } else if (entry.target === showLessRef.current) {
            setVisibleHistoryCount(50);
          }
        }
      });
    });

    if (showMoreRef.current) {
      observer.observe(showMoreRef.current);
    }
    if (showLessRef.current) {
      observer.observe(showLessRef.current);
    }

    return () => {
      if (showMoreRef.current) {
        observer.unobserve(showMoreRef.current);
      }
      if (showLessRef.current) {
        observer.unobserve(showLessRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="mb-4">
        <div className="flex-grow mb-1">
          <label className="block text-sm font-medium text-gray-700">
            {L.dashboard.search}
          </label>
          <div className="flex items-center relative">
            <input
              type="text"
              className="flex-grow p-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder={L.dashboard.search}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <Button
                className="absolute right-4"
                variant="unstyled"
                icon={
                  <Icon
                    name="XCircleIcon"
                    role="informative"
                    title="Clear search terms" />
                }
                onClick={() => setSearchTerm('')
                }
              />
            )}
            <Button
              className="!p-0.5"
              variant="unstyled"
              icon={
                <Icon
                  name="FunnelIcon"
                  role="informative"
                  title="Filter"
                />
              }
              onClick={() => setShowSearchOptions(!showSearchOptions)}
            />
          </div>
        </div>

        {showSearchOptions && <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-sm">
          <div className="flex items-center cursor-pointer">
            <input
              id="exactMatch"
              type="checkbox"
              className="h-4 w-4 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer"
              checked={exactMatch}
              onChange={() => setExactMatch(!exactMatch)}
            />
            <label htmlFor="exactMatch" className="ml-2 mb-0  text-gray-700 cursor-pointer">
              {L.applicant.history.exact_match}
            </label>
          </div>
          <div className="flex items-center cursor-pointer">
            <input
              id="searchValue"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer"
              checked={searchValue}
              onChange={() => setSearchValue(!searchValue)}
            />
            <label htmlFor="searchValue" className="ml-2 mb-0  text-gray-700 cursor-pointer">
              {L.applicant.history.search_values}
            </label>
          </div>
          <div className="flex items-center cursor-pointer">
            <input
              id="searchTargetField"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer"
              checked={searchTargetField}
              onChange={() => setSearchTargetField(!searchTargetField)}
            />
            <label htmlFor="searchTargetField" className="ml-2 mb-0  text-gray-700 cursor-pointer">
              {L.applicant.history.search_target_fields}
            </label>
          </div>
          <div className="flex items-center cursor-pointer">
            <input
              id="searchAuthor"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer"
              checked={searchAuthor}
              onChange={() => setSearchAuthor(!searchAuthor)}
            />
            <label htmlFor="searchAuthor" className="ml-2 mb-0  text-gray-700 cursor-pointer">
              {L.applicant.history.search_authors}
            </label>
          </div>
          <div className="flex items-center cursor-pointer">
            <input
              id="onlyShowLatest"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer"
              checked={onlyShowLatest}
              onChange={() => setOnlyShowLatest(!onlyShowLatest)}
            />
            <label htmlFor="onlyShowLatest" className="ml-2 mb-0 text-gray-700 cursor-pointer">
              {L.applicant.history.only_show_latest}
            </label>
          </div>
          <div className="flex items-center cursor-pointer">
            <input
              id="hideEmptyErrors"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer"
              checked={hideEmptyErrors}
              onChange={() => setHideEmptyErrors(!hideEmptyErrors)}
            />
            <label htmlFor="hideEmptyErrors" className="ml-2 mb-0 text-gray-700 cursor-pointer">
              {L.applicant.history.hide_empty_errors}
            </label>
          </div>
        </div>}
      </div>
      {isLoading && <div className="flex justify-center">
        <SpacedSpinner className="text-gray-500" />
      </div>}
      <div className="overflow-hidden">
        <div ref={showLessRef} />
        <ul className="divide-y divide-gray-200">
          {filteredHistory.slice(0, visibleHistoryCount).map((i: any) => (
            <HistoryItemComponent key={i.id + '_history'} item={i} config={config} searchTerm={searchTerm} />
          ))}
        </ul>
        <div ref={showMoreRef} />
      </div>
    </div>
  );
}
