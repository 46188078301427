import { useEffect, useState } from "react";
import { usePost } from "./API";
import { Button } from "@aidkitorg/component-library";

export default function CronPage() {

  const getJobs = usePost('/dev/cron/enumerate');
  const triggerJob = usePost('/admin/cron/schedule');
  const [jobs, setJobs] = useState<string[]>([]);
    

  useEffect(() => {
    getJobs({}).then((resp) => {
      setJobs(resp.jobs);
    })
  }, [])

  return <>
    <h1>Cron Jobs</h1>
    <div>
      {jobs.map((job) => <div className='mb-1' key={job}>{job} 
                &nbsp;<Button variant='secondary' className='p-1 ml-2' onClick={async () => alert(JSON.stringify(await triggerJob({ name: job })))}> run </Button>
      </div> )}
    </div>
  </>;
}
