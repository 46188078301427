import React, { useEffect, useState, useReducer, useContext, useCallback, useMemo, useRef, Dispatch, SetStateAction } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import InterfaceContext from "../Context";
import { legalNameValidator } from "../utils/nameValidator";
import { ValidatorMessage } from "../Components/ValidatorMessage";
import { InfoDict, QuestionProps } from "./Props";
import { safeParse } from "../Util";
import type { Role, Text, RichText, TargetFieldRef } from "@aidkitorg/types/lib/survey";
import { v4 } from 'uuid';
import { useLocalizedStrings } from "../Localization";
import { useToast } from "@aidkitorg/component-library";

export type HouseholdMember = {
  id: string;
  roleType: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  birth_date?: string;
  birth_year?: string;
  dependence?:
  | "independent"
  | "dependent_on_applicant"
  | "dependent_on_someone_else"
  | "applicant_dependent_on_them"
  | "";
  [key: string]: string | undefined;
};

type HouseholdDispatch = Dispatch<{
  type: "add" | "edit" | "delete";
  payload: HouseholdMember;
}>

type Date =
  | {
    year: number;
    month: number;
    day: number;
  }
  | TargetFieldRef
  | undefined;

function getReferenceDateMs(date: Date, info: InfoDict): number {
  // if no reference date is set, use "now"
  if (!date) {
    return new Date().getTime();
  }

  // if a reference date is a string, it's a target field name, so the field value is the date
  if (typeof date === 'string') {
    const newDate = new Date(info[date] || '').getTime();

    // if the field doesn't exist or the value can't be parsed as a date, return 0 to indicate a problem
    if (!info[date] || isNaN(newDate)) {
      return 0;
    }

    return newDate;
  }

  // otherwise, the reference date is a fixed date
  return new Date(`${date.month}/${date.day}/${date.year}`).getTime();
};

function MemberForm(props: {
  roleInfo: Role;
  memberInfo: HouseholdMember;
  householdDispatch: HouseholdDispatch;
  isSaved?: boolean;
  referenceDateMs: number;
}) {
  const L = useLocalizedStrings();
  const {
    collectBirthdate,
    requireFullName,
    hideName,
    dependence,
    customQuestions,
  } = props.roleInfo.dataCollectOptions || {};
  const validateFullName = typeof requireFullName === 'boolean' ? requireFullName : requireFullName === 'Full name';

  const reducer = (state: HouseholdMember, action: {
    type: "set";
    payload: { key: string, value: string };
  }) => {
    switch (action.type) {
      case "set":
        return {
          ...state,
          [action.payload.key]: action.payload.value,
        };
      default:
        return state;
    }
  };
  const [memberValidState, memberDispatch] = useReducer(reducer, props.memberInfo);
  const [ageLabel, setAgeLabel] = useState("");
  const firstLoad = useRef(true);
  const recentlySaved = useRef(false);
  const previousValidState = useRef(JSON.stringify(memberValidState));
  const context = useContext(InterfaceContext);

  useEffect(() => {
    // Keep household form state current with valid values from member forms
    const newValidState = JSON.stringify(memberValidState);
    if (newValidState !== previousValidState.current) {
      previousValidState.current = newValidState;
      props.householdDispatch({
        type: "edit",
        payload: memberValidState,
      });
    }
  }, [memberValidState]);

  useEffect(() => {
    if (!firstLoad.current && props.isSaved) {
      recentlySaved.current = true;
    }
    firstLoad.current = false;
  }, [props.isSaved]);

  return (
    <div className="w-full flex flex-col items-end pb-1 pt-3 px-2.5 sm:px-4 last:pb-0 rounded-md border border-solid border-gray-200">
      <button
        className="w-9 h-9 ml-2 sm:mb-3 border border-gray-300 shadow-sm text-sm font-medium rounded-md group flex items-center justify-center hover:!bg-red-500 text-red-500 bg-white hover:text-white transition-all"
        onClick={() =>
          props.householdDispatch({
            type: "delete",
            payload: memberValidState,
          })
        }
      >
        <TrashIcon className="inline-block h-5 w-5" />
      </button>
      <div className="w-full flex flex-col items-end gap-3">
        {!hideName && (
          <div className="w-full flex flex-col sm:flex-row sm:items-center">
            <label
              className="text-sm font-medium text-gray-700 flex-none w-28 sm:mt-1"
              htmlFor={`name_${memberValidState.id}`}
            >
              {requireFullName === 'Split first and last' 
                ? L.questions.household.first_name 
                : ( validateFullName
                  ? L.questions.household.full_name
                  : L.questions.household.name)}
            </label>
            <Name
              id={memberValidState.id}
              value={(requireFullName === 'Split first and last' 
                ? memberValidState.first_name
                : memberValidState.name) || ''}
              saveKey={requireFullName === 'Split first and last' ? 'first_name' : 'name'}
              requireFullName={validateFullName}
              updateMember={(key, value) => {
                memberDispatch({
                  type: "set",
                  payload: {
                    key,
                    value,
                  },
                });
              }}
            />
          </div>
        )}
        {!hideName && requireFullName === 'Split first and last' && (
          <div className="w-full flex flex-col sm:flex-row sm:items-center">
            <label
              className="text-sm font-medium text-gray-700 flex-none w-28 sm:mt-1"
              htmlFor={`name_${memberValidState.id}`}
            >
              {L.questions.household.last_name}
            </label>
            <Name
              id={memberValidState.id}
              value={memberValidState.last_name || ''}
              saveKey={'last_name'}
              requireFullName={false}
              updateMember={(key, value) => {
                memberDispatch({
                  type: "set",
                  payload: {
                    key,
                    value,
                  },
                });
              }}
            />
          </div>
        )}

        {collectBirthdate === "full" && (
          <div className="w-full">
            <div className="flex flex-col sm:flex-row sm:items-start justify-between sm:border-gray-200">
              <div className="flex sm:flex-col justify-between items-end sm:items-start">
                <label
                  htmlFor={"birth_month_" + memberValidState.id}
                  className="text-sm font-medium text-gray-700 mb-0.5 flex-none w-28 sm:mt-1"
                >
                  {L.questions.household.birth_date}
                </label>
                {ageLabel && (
                  <div className="text-sm right-0 -top-5 sm:top-6 sm:-left-28 text-gray-400">
                    {ageLabel}
                  </div>
                )}
              </div>
              <div className="w-full flex-1">
                <Birthdate
                  id={memberValidState.id}
                  value={memberValidState.birth_date || ""}
                  referenceDateMs={props.referenceDateMs}
                  updateMember={(key, value) => {
                    memberDispatch({
                      type: "set",
                      payload: { key, value },
                    });
                  }}
                  setAgeLabel={(label) => setAgeLabel(label)}
                />
              </div>
            </div>
          </div>
        )}

        {collectBirthdate === "year_only" && (
          <div className="col-span-2 sm:gap-4 w-full sm:border-t sm:border-gray-200 sm:pt-2">
            <BirthYear
              memberId={memberValidState.id}
              value={memberValidState.birth_year || ""}
              updateMember={(key, value) => {
                memberDispatch({ type: "set", payload: { key, value } });
              }}
            />
          </div>
        )}

        {dependence === "collect" && (
          <div className="w-full flex flex-col sm:flex-row sm:items-center">
            <label
              htmlFor={"dependence_" + props.memberInfo.id}
              className="block text-sm font-medium text-gray-700 mb-1 sm:flex-none sm:w-28 sm:mt-1"
            >
              {L.questions.household.financial_dependence}
            </label>
            <select
              id={"dependence_" + props.memberInfo.id}
              name="dependence"
              className={
                "sm:mt-0 block w-full rounded-md px-2 h-10 cursor-pointer text-base bg-white appearance-none " +
                (memberValidState.dependence
                  ? "text-black border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                  : "text-gray-500 border-2 border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
              }
              value={memberValidState.dependence || "--"}
              onChange={(e) => {
                memberDispatch({
                  type: "set",
                  payload: { key: "dependence", value: e.target.value },
                });
              }}
            >
              <option value="--" disabled hidden>{L.questions.household.please_select_one}</option>
              <option value="independent">{L.questions.household.independent}</option>
              <option value="dependent_on_applicant">{L.questions.household.dependent_on_me}</option>
              <option value="dependent_on_someone_else">{L.questions.household.dependent_on_someone_else}</option>
              <option value="applicant_dependent_on_them">{L.questions.household.im_dependent_on_them}</option>
            </select>
          </div>
        )}

        {customQuestions?.map((question, index) => {
          if (question.kind === "Select") {
            return (
              <div className="w-full flex flex-col sm:flex-row sm:items-start">
                <label
                  htmlFor={question.key + "_" + index}
                  className="block text-sm font-medium text-gray-700 mb-1 sm:flex-none sm:w-28 sm:mt-1"
                >
                  {(question.content)[context.lang as keyof RichText]}
                </label>
                <select
                  id={question.key + "_" + index}
                  name={question.key}
                  className={
                    "sm:mt-0 block w-full rounded-md px-2 h-10 cursor-pointer text-base bg-white appearance-none " +
                    (memberValidState[question.key]
                      ? "text-black border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                      : "text-gray-500 border-2 border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
                  }
                  value={memberValidState[question.key] || "--"}
                  onChange={(e) => {
                    memberDispatch({
                      type: "set",
                      payload: { key: question.key, value: e.target.value },
                    });
                  }}
                >
                  <option value="--" disabled hidden>{L.questions.household.please_select_one}</option>
                  {question.choices.map((choice) => (
                    <option value={choice.value}>{(choice.label)[context.lang as keyof 
                    RichText]}</option>
                  ))}
                </select>
              </div>
            );
          }

          if (question.kind === "TextEntry") {
            return (
              <div className="w-full flex flex-col sm:flex-row sm:items-start">
                <label
                  htmlFor={question.key + "_" + index}
                  className="block text-sm font-medium text-gray-700 mb-1 sm:flex-none sm:w-28 sm:mt-1"
                >
                  {(question.content)[context.lang as keyof RichText]}
                </label>
                <input
                  className={
                    "block w-full shadow-sm border-2 border-solid px-2 h-10 rounded-md text-base " +
                    (memberValidState[question.key]
                      ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                      : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
                  }
                  value={memberValidState[question.key]}
                  id={question.key + "_" + index}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    memberDispatch({
                      type: "set",
                      payload: { key: question.key, value: e.target.value },
                    });
                  }}
                  placeholder=""
                />
              </div>
            )
          }

          if (question.kind === 'Date') {
            return (
              <div className="w-full flex flex-col sm:flex-row sm:items-start">
                <div className="flex sm:flex-col justify-between items-end sm:items-start">
                  <label
                    htmlFor={"month_" + memberValidState.id}
                    className="block text-sm font-medium text-gray-700 mb-1 sm:flex-none sm:w-28 sm:mt-1"
                  >
                    {(question.content)[context.lang as keyof RichText]}
                  </label>
                  {ageLabel && (
                    <div className="text-sm right-0 -top-5 sm:top-6 sm:-left-28 text-gray-400">
                      {ageLabel}
                    </div>
                  )}
                </div>
                <CustomDate
                  id={memberValidState.id}
                  value={(memberValidState[question.key] || memberValidState.birth_date) || ""}
                  question={question}
                  ageLabel={ageLabel}
                  referenceDateMs={props.referenceDateMs}
                  updateMember={(key, value) => {
                    memberDispatch({
                      type: "set",
                      payload: { key, value },
                    });
                  }}
                  setAgeLabel={(label) => setAgeLabel(label)}
                />
              </div>
            );
          }
        })}

        <div className="flex items-center text-green-500 mb-2 gap-2">
          {props.isSaved && recentlySaved.current && (<span className="font-semibold text-base">{L.questions.household.looks_good}</span>)}
          {props.isSaved && <CheckIcon className="h-8 w-8" />}
        </div>
      </div>
    </div>
  );
}

function MemberDisplayGroup(props: {
  label: string;
  members: HouseholdMember[];
}) {
  return (
    <div className="my-3 sm:mx-3">
      {props.label && (
        <div className="flex flex-1 gap-2 text-gray-700 font-bold text-xl mt-4 mb-2">
          {props.label}
        </div>
      )}
      <div className="px-2 py-4 w-full mr-0 rounded-lg border border-solid">
        {props.members.map((member) => (
          <div key={member.id} className="w-full flex justify-between items-end">
            <div key={member.id} className=" sm:px-3 flex flex-col gap-1">
              <div className="text-gray-700">{member.name}</div>
              {member.birth_date && (
                <div className="text-gray-400 text-sm">{member.birth_date}</div>
              )}
            </div>
            <div/>
            <CheckIcon className="sm:mx-3 h-7 w-7 text-base text-green-500" />
          </div>
        ))}
      </div>
    </div>
  );
}

const msPerDay = 1000 * 60 * 60 * 24;

function MemberFormGroup(props: {
  info: InfoDict;
  roleInfo: Role;
  membersWithForm: HouseholdMember[];
  membersSaved: HouseholdMember[];
  referenceDateMs: number;
  householdDispatch: HouseholdDispatch;
  initializeMemberInfo: (role: Role) => HouseholdMember;
}) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const { roleInfo, membersWithForm, membersSaved, householdDispatch, referenceDateMs } = props;
  const { label, dataCollectOptions, displayOptions } = roleInfo;
  let maxCount: number | undefined;
  // check if maxCount has been set
  if (typeof dataCollectOptions.maxCount !== undefined && dataCollectOptions.maxCount !== null) {
    const maxCountTemp = dataCollectOptions.maxCount
    // if it's a number set it
    if (typeof maxCountTemp === 'number') {
      maxCount = maxCountTemp
    // otherwise look for a TargetFieldRef with a value that can be parsed to an int
    } else if (maxCountTemp && maxCountTemp['field'] && props.info[maxCountTemp['field']] !== undefined && props.info[maxCountTemp['field']] !== '') {
      maxCount = !isNaN(parseInt(props.info[maxCountTemp['field']] || '')) ? parseInt(props.info[maxCountTemp['field']] || '') : undefined;
    }
  }
  const allFormFieldsComplete = membersWithForm.every((p) =>
    Object.values(p).every((v) => v)
  );

  let roleCountText = "";
  if (!displayOptions.hideCount) {
    if (membersSaved.length === 1) {
      roleCountText = "1 " + label.singular[context.lang];
    } else {
      roleCountText = membersSaved.length + " " + (label?.plural?.[context.lang] || label.singular[context.lang]);
    }
  }

  return (
    <div className="mt-4 mb-2.5 sm:mx-3">
      <div className="flex-1 flex text-gray-700 font-bold text-xl my-2">
        {maxCount === 1 || !label.plural
          ? label.singular[context.lang] : label.plural[context.lang]}
      </div>
      <div className="flex flex-col w-full gap-2.5 sm:gap-3">
        {membersWithForm.map(member => (
          <MemberForm
            key={member.id}
            roleInfo={props.roleInfo}
            memberInfo={member}
            isSaved={membersSaved.some((p) => p.id === member.id)}
            referenceDateMs={referenceDateMs}
            householdDispatch={householdDispatch}
          />
        ))}
      </div>
      <div className="flex flex-col gap-1.5 w-full">
        {!displayOptions.hideCount && <div className="mt-2.5 text-[17px] text-gray-500">
          {roleCountText}
        </div>}
        {(maxCount === undefined || membersWithForm.length < maxCount) && (
          <button
            className={
              (allFormFieldsComplete
                ? "text-white bg-indigo-600 hover:bg-indigo-700"
                : "bg-gray-50 text-gray-300")
                + " w-full sm:w-[fit-content] mt-2.5 px-4 py-2 min-h-10 border border-gray-300 shadow-sm text-sm font-medium rounded-md"
            }
            onClick={() => {
              householdDispatch({
                type: "add",
                payload: props.initializeMemberInfo(roleInfo) as HouseholdMember,
              });
            }}
            disabled={!allFormFieldsComplete}
          >
            + {L.questions.household.add} {roleInfo.label.singular[context.lang]}
          </button>
        )}
      </div>
    </div>
  );
}

function Name(props: {
  id: string;
  value: string;
  requireFullName?: boolean;
  saveKey?: string;
  updateMember: (key: string, value: string) => void;
}) {
  const L = useLocalizedStrings();
  const [value, setValue] = useState(props.value || "");
  const [valid, setValid] = useState(false);
  const [pending, setPending] = useState(true);

  let validatorMessage;
  let validator = (v: string) => v.length > 0;
  if (props.requireFullName) {
    validatorMessage = L.questions.household.must_have_first_and_last_name;
    validator = (v) => legalNameValidator(v);
  }

  function checkIfValid(formValue?: string) {
    const isValid = validator(formValue ?? value);
    setValid(isValid);
    props.updateMember(props.saveKey || "name", isValid ? formValue || value : "");
  }
  
  useEffect(() => {
    checkIfValid();
  }, []);

  return (
    <fieldset className="flex-1">
      <div className="flex flex-row h-10">
        <input
          className={
            "block w-full shadow-sm border-2 border-solid px-2 h-10 rounded-md text-base " +
            (valid
              ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
              : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
          }
          value={value}
          id={`name_${props.id}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            checkIfValid(e.target.value);
          }}
          onBlur={(e) => {
            if (pending) setPending(false);
            checkIfValid(e.target.value);
          }}
          placeholder=""
        />
      </div>
      {!pending && !valid && validatorMessage && (
        <div className="text-sm">
          <ValidatorMessage message={validatorMessage as string} />
        </div>
      )}
    </fieldset>
  );
}

function BirthYear(props: {
  value: string;
  memberId: string;
  updateMember: (key: string, value: string) => void;
}) {
  const L = useLocalizedStrings();
  const [year, setYear] = useState(props.value || "");
  const [error, setError] = useState("");
  const [pending, setPending] = useState(true);

  const yearDifference = new Date().getFullYear() - parseInt(year);
  const inFuture = yearDifference < 0;
  const valid = yearDifference <= 125;

  useEffect(() => {
    if (!valid) {
      setError(L.questions.household.invalid_year);
    } else if (inFuture) {
      setError(L.questions.household.must_be_in_the_past);
    } else {
      setError('');
    }
    props.updateMember("birth_year", (valid && !inFuture) ? year : "");
  }, [year, inFuture, valid]);


  return (
    <fieldset className="flex-1">
      <div className="flex flex-col sm:flex-row sm:items-center">
        <label
          htmlFor={"birth_year_" + props.memberId}
          className="text-sm font-medium text-gray-700 mb-1 flex-none w-28 sm:mt-1"
        >
          {L.questions.household.birth_year}
        </label>
        <div>
          <input
            className={
              "no-num-spinners max-w-[100px] block w-full h-10 shadow-sm border-2 border-solid p-2 mb-1 text-base rounded-md " +
              (valid
                ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
            }
            value={year}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 4) setPending(false);
              setYear(e.target.value.slice(0, 4));
            }}
            onBlur={() => setPending(false)}
            placeholder={L.date.yyyy}
            type="number"
          />
          {!pending && error && (
            <div className="text-sm">
              <ValidatorMessage message={error} />
            </div>
          )}
        </div>
      </div>
    </fieldset>
  );
}

function Birthdate(props: {
  id: string;
  value: string;
  referenceDateMs: number;
  updateMember: (key: string, value: string) => void;
  setAgeLabel: (ageLabel: string) => void;
}) {
  const prev = (props.value || "").match(
    /^(01|02|03|04|05|06|07|08|09|10|11|12)\/([0-3][0-9])\/((19|20)[0-9][0-9])$/
  );

  const L = useLocalizedStrings();
  const [month, setMonth] = React.useState(prev ? prev[1] : "--");
  const [day, setDay] = React.useState(prev ? prev[2] : "");
  const [year, setYear] = React.useState(prev ? prev[3] : "");
  const [pending, setPending] = React.useState(!prev);
  const [error, setError] = React.useState("");

  const enteredDateMs = new Date(`${month}/${day}/${year}`).getTime();
  
  const ageNumber = Math.floor((props.referenceDateMs - enteredDateMs) / (365.25 * msPerDay))

  const validStatus = pending
    ? "incomplete"
    : DateTime.fromISO(`${year}-${month}-${day}`).invalidReason || ageNumber > 125
      ? "invalid"
      : enteredDateMs > props.referenceDateMs
        ? "inFuture"
        : "valid";

  let ageLabel = "";  
  if (validStatus === "valid") {
    const ageDescription =
      ageNumber <= 1
        ? " " + L.questions.household.year_old
        : " " + L.questions.household.years_old;
    ageLabel =
      ageNumber < 1
        ? "< 1" + ageDescription
        : JSON.stringify(ageNumber) + ageDescription;
  }

  useEffect(() => {
    if (validStatus === "incomplete") {
      return;
    }
    if (validStatus === "valid") {
      setError("");
      props.updateMember("birth_date", `${month}/${day}/${year}`);
      // Save age at the time birthdate is set for #sifh application (consider removing after June 2023)
      props.updateMember("age", JSON.stringify(ageNumber));
    } else {
      if (validStatus === "inFuture") {
        setError(L.questions.household.must_be_in_the_past);
      } else {
        setError(L.questions.household.invalid_date);
      }
      props.updateMember("birth_date", "");
      props.updateMember("age", "")
    }
    props.setAgeLabel(ageLabel);
  }, [day, month, year, validStatus]);

  return (
    <fieldset>
      <div className="relative flex justify-between gap-2 sm:gap-3 w-full sm:visible sm:flex-wrap">
        <div className="flex-grow basis-32">
          <select
            id={"birth_month_" + props.id}
            name={"date_" + props.id}
            className={
              "appearance-none my-1 shadow-sm h-10 inline-block w-full bg-white pl-2 pr-0 sm:pr-2 text-base border-2 rounded-md cursor-pointer " +
              (validStatus === "valid" && !error
                ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400 "
                : validStatus === "valid" && pending
                  ? "border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400 "
                  : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400 ")
                + (month !== "--" ? "text-black" : "text-gray-500")
            }
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            <option className="text-gray-200" value="--" disabled hidden>
              {L.months.month}
            </option>
            <option value="01">{L.months.jan}</option>
            <option value="02">{L.months.feb}</option>
            <option value="03">{L.months.mar}</option>
            <option value="04">{L.months.apr}</option>
            <option value="05">{L.months.may}</option>
            <option value="06">{L.months.jun}</option>
            <option value="07">{L.months.jul}</option>
            <option value="08">{L.months.aug}</option>
            <option value="09">{L.months.sep}</option>
            <option value="10">{L.months.oct}</option>
            <option value="11">{L.months.nov}</option>
            <option value="12">{L.months.dec}</option>
          </select>
        </div>
        <div className="">
          <input
            id={"day_" + props.id}
            className={
              "no-num-spinners max-w-lg rounded-md appearance-none inline-block w-11 shadow-sm border-2 border-solid px-2 h-10 mt-1 mb-1 sm:max-w-xs text-base " +
              (validStatus === "valid" && !error
                ? "border-green-200 focus:ring-green-400 focus:border-green-400"
                : validStatus === "valid" && pending
                  ? "border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400"
                  : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
            }
            placeholder={L.date.dd}
            pattern="([0-2]\d|3[0-1])"
            value={day}
            onChange={(e) => setDay(e.target.value.slice(0, 2))}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                setDay("0" + e.target.value);
              }
            }}
            type="number"
          />
        </div>
        <span className="whitespace-nowrap">
          <input
            id={"year_" + props.id}
            pattern="[1-2][0-9]{3}"
            className={
              "no-num-spinners max-w-lg inline-block w-16 shadow-sm border-2 border-solid px-2 h-10 my-1 sm:max-w-xs rounded-md text-base " +
              (validStatus === "valid" && !error
                ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                : validStatus === "valid" && pending
                  ? "border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400"
                  : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
            }
            placeholder={L.date.yyyy}
            value={year}
            onChange={(e) => {
              setYear(e.target.value.slice(0, 4));
              if (e.target.value.length === 4) setPending(false);
            }}
            onBlur={(e) => {
              if (e.target.value.length > 0) setPending(false);
            }}
            type="number"
          />
        </span>
      </div>
      {error && !pending && (
        <div className="text-sm">
          <ValidatorMessage message={error} />
        </div>
      )}
    </fieldset>
  );
}

function CustomDate(props: {
  id: string;
  value: string;
  question: any;
  ageLabel: string;
  referenceDateMs: number;
  updateMember: (key: string, value: string) => void;
  setAgeLabel: (ageLabel: string) => void;
}) {
  const prev = (props.value || "").match(
    /^(01|02|03|04|05|06|07|08|09|10|11|12)\/([0-3][0-9])\/((19|20)[0-9][0-9])$/
  );
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const [month, setMonth] = React.useState(prev ? prev[1] : '--');
  const [day, setDay] = React.useState(prev ? prev[2] : '');
  const [year, setYear] = React.useState(prev ? prev[3] : '');
  const [pending, setPending] = React.useState(!prev);
  const [error, setError] = React.useState('');

  const enteredDateMs = new Date(`${month}/${day}/${year}`).getTime();

  const {
    showAge,
    minAllowedTimeInPast,
    maxAllowedTimeInPast,
    minAllowedTimeInFuture,
    maxAllowedTimeInFuture,
  } = props.question;

  const getTimeMs = (time: { amount: number, unit: 'days' | 'weeks' | 'months' | 'years' }) => {
    const { amount, unit } = time;
  
    switch (unit) {
      case 'days': return amount * msPerDay;
      case 'weeks': return amount * 7 * msPerDay;
      case 'months': return amount * (365.25/12) * msPerDay;
      case 'years': return amount * 365.25 * msPerDay;
      default: return 0;
    }
  }

  const dateDiffMs = props.referenceDateMs - enteredDateMs; // negative if entered date is in the future

  const getValidStatus = () => {
    // User has not yet typed input in every field
    if (pending) return 'incomplete';
    // User has entered a date that doesn't exist
    if (DateTime.fromISO(`${year}-${month}-${day}`).invalidReason) return 'invalid';

    if (maxAllowedTimeInPast && dateDiffMs > getTimeMs(maxAllowedTimeInPast)) return 'tooFarInPast';
    if (minAllowedTimeInPast && dateDiffMs < getTimeMs(minAllowedTimeInPast)) return 'tooRecentInPast';
    if (maxAllowedTimeInFuture && -dateDiffMs > getTimeMs(maxAllowedTimeInFuture)) return 'tooFarInFuture';
    if (minAllowedTimeInFuture && -dateDiffMs < getTimeMs(minAllowedTimeInFuture)) return 'tooSoonInFuture';

    return 'valid';
  }

  const errorMessageMap = {
    invalid: L.questions.household.invalid_date,
    tooFarInPast: maxAllowedTimeInPast?.errorMessage?.[context.lang],
    tooFarInFuture: maxAllowedTimeInFuture?.errorMessage?.[context.lang],
    tooRecentInPast: minAllowedTimeInPast?.errorMessage?.[context.lang],
    tooSoonInFuture: minAllowedTimeInFuture?.errorMessage?.[context.lang]
  };

  const validStatus = getValidStatus();

  useEffect(() => {
    if (validStatus === 'incomplete') return;

    const ageNumber = Math.floor(dateDiffMs / (365.25 * msPerDay)); // considering leap years

    const handleValidDate = () => {
      setError('');
      props.updateMember(props.question.key, `${month}/${day}/${year}`);
      if (showAge) {
        props.updateMember('age', String(ageNumber));
        props.setAgeLabel(getAgeLabel(ageNumber));
      }
    };

    const handleInvalidDate = () => {
      setError(errorMessageMap[validStatus as keyof typeof errorMessageMap] || L.questions.household.invalid_date);
      props.updateMember(props.question.key, '');
      if (showAge) {
        props.updateMember('age', '');
        props.setAgeLabel('');
      }
    }

    if (validStatus === 'valid') {
      handleValidDate();
    } else {
      handleInvalidDate();
    }
  }, [day, month, year, validStatus]);

  const getAgeLabel = (ageNumber: number) => {
    const ageDescription = ageNumber <= 1
      ? L.questions.household.year_old
      : L.questions.household.years_old;
  
    return `${ageNumber < 1 ? "< 1" : ageNumber} ${ageDescription}`;
  };
  
  return (
    <fieldset>
      <div className="relative flex justify-between gap-2 sm:gap-3 w-full sm:visible sm:flex-wrap">
        <div className="flex-grow basis-32">
          <select
            id={"month_" + props.id}
            name={"date_" + props.id}
            className={
              "appearance-none my-1 shadow-sm h-10 inline-block w-full bg-white pl-2 pr-0 sm:pr-2 text-base border-2 rounded-md cursor-pointer " +
              (validStatus === "valid"
                ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400 "
                : pending
                  ? "border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400 "
                  : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400 ")
                + (month !== "--" ? "text-black" : "text-gray-500")
            }
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            <option className="text-gray-200" value="--" disabled hidden>
              {L.months.month}
            </option>
            <option value="01">{L.months.jan}</option>
            <option value="02">{L.months.feb}</option>
            <option value="03">{L.months.mar}</option>
            <option value="04">{L.months.apr}</option>
            <option value="05">{L.months.may}</option>
            <option value="06">{L.months.jun}</option>
            <option value="07">{L.months.jul}</option>
            <option value="08">{L.months.aug}</option>
            <option value="09">{L.months.sep}</option>
            <option value="10">{L.months.oct}</option>
            <option value="11">{L.months.nov}</option>
            <option value="12">{L.months.dec}</option>
          </select>
        </div>
        <div className="">
          <input
            id={"day_" + props.id}
            className={
              "no-num-spinners max-w-lg rounded-md appearance-none inline-block w-11 shadow-sm border-2 border-solid px-2 h-10 mt-1 mb-1 sm:max-w-xs text-base " +
              (validStatus === "valid"
                ? "border-green-200 focus:ring-green-400 focus:border-green-400"
                : pending
                  ? "border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400"
                  : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
            }
            placeholder={L.date.dd}
            pattern="([0-2]\d|3[0-1])"
            value={day}
            onChange={(e) => setDay(e.target.value.slice(0, 2))}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                setDay('0' + e.target.value);
              }
            }}
            type="number"
          />
        </div>
        <span className="whitespace-nowrap">
          <input
            id={"year_" + props.id}
            pattern="[1-2][0-9]{3}"
            className={
              "no-num-spinners max-w-lg inline-block w-16 shadow-sm border-2 border-solid px-2 h-10 my-1 sm:max-w-xs rounded-md text-base " +
              (validStatus === "valid"
                ? "border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"
                : pending
                  ? "border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400"
                  : "border-red-200 focus:ring-red-400 ring-red-400 focus:border-red-400")
            }
            placeholder={L.date.yyyy}
            value={year}
            onChange={(e) => {
              setYear(e.target.value.slice(0, 4));
              if (e.target.value.length === 4) setPending(false);
            }}
            onBlur={(e) => {
              if (e.target.value.length > 0) setPending(false);
            }}
            type="number"
          />
        </span>
      </div>
      {error && !pending && (
        <div className="text-sm">
          <ValidatorMessage message={error} />
        </div>
      )}
    </fieldset>
  );
}

export default function HouseholdCalculation(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const { toast } = useToast();
  const { roles, title, showYou, memberCountLabel, referenceDate } = useMemo<{
    roles: Role[];
    title: Text;
    info: InfoDict;
    showYou?: {
      nameField: string;
      birthdateField?: string | undefined;
    };
    memberCountLabel?:{
      singular: Text;
      plural: Text;
    };
    referenceDate?: Date;
  }>(() => safeParse(props.Metadata || "{}"), [props.Metadata]);
  const householdInfo = useMemo<Record<string, HouseholdMember[]>>(() => {
    const info = props.info[props["Target Field"] || ""];
    return safeParse(info || "{}");
  }, [props.info]);

  const reducer = (
    state: Record<string, HouseholdMember[]>,
    action: { type: string; payload: HouseholdMember }
  ) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.payload.roleType]: [
            ...(state[action.payload.roleType] || []),
            action.payload,
          ],
        };
      case "edit":
        const stateAfterEditing =
          state[action.payload.roleType]?.map((member: HouseholdMember) => {
            return member.id === action.payload.id ? action.payload : member;
          }) || [];
        return {
          ...state,
          [action.payload.roleType]: stateAfterEditing,
        };
      case "delete":
        const stateAfterDeleting = (state[action.payload.roleType] || []).filter(
          (member: HouseholdMember) => member.id !== action.payload.id
        );
        if (stateAfterDeleting.length === 0) {
          const {
            [action.payload.roleType]: value,
            ...stateWithDeletedKeyRemoved
          } = state;
          return stateWithDeletedKeyRemoved;
        } else {
          return {
            ...state,
            [action.payload.roleType]:
              state[action.payload.roleType].filter(
                (member: HouseholdMember) => member.id !== action.payload.id
              ) || [],
          };
        }
      default:
        return state;
    }
  };

  // householdState represents the current form state for all household members
  // It contains all required keys for each member with all valid values (some values may be empty strings)
  const [householdFormState, householdDispatch] = useReducer(
    reducer,
    householdInfo || {}
  );

  // This is used to trigger the component to rerender (which saves info and allows proceeding with the survey)
  // if the applicant is prepopulated via showYou, and there is no one else they need to add to their household
  const [noOthers, setNoOthers] = useState(false);

  // householdValidState represents members with complete information (no empty values)
  const householdValidState = useMemo(() => {
    const hhState = Object.keys(householdFormState).reduce((newState: Record<string, HouseholdMember[]>, roleType: string) => {
      const validMembers = householdFormState[roleType].filter(
        (member: HouseholdMember) => {
          return Object.values(member).every((v) => v);
        }
      );
      // exclude empty arrays from the final state
      if (validMembers.length > 0) {
        newState[roleType] = validMembers;
      }
      return newState;
    }, {});

    if (showYou?.nameField) {
      hhState['system_you'] = [{
        id: 'you',
        roleType: 'system_you',
        name: props.info[showYou.nameField],
        birth_date: showYou?.birthdateField ? props.info[showYou.birthdateField] : undefined
      }];

      // This is used to make sure we change the value in the Household Calculator's target field if the name or birthdate that it is
      // pulling in changes
      const infoChanged =
        !!householdInfo['system_you'] && (householdInfo['system_you'][0].name !== props.info[showYou.nameField]
          || householdInfo['system_you'][0].birth_date !== (showYou.birthdateField && props.info[showYou.birthdateField]));

      const memberCount = Object.values(householdInfo).reduce((total: number, role: HouseholdMember[]) => total + role.length, 0);

      // If we have never set the target field or something has changed, set it now.
      // Also wait to save until the user either hits "No Others" or adds someone besides themself.
      if (props['Target Field']
          && (!props.info[props['Target Field']] || infoChanged)
          && (noOthers === true || (memberCount > (showYou ? 1 : 0)))) {
        props.setInfoKey(
          props['Target Field'],
          JSON.stringify(hhState),
          true,
          false,
        );
      }
    }
    return hhState;
  }, [householdFormState, noOthers, showYou?.nameField && props.info[showYou.nameField], showYou?.birthdateField && props.info[showYou.birthdateField]]);

  useEffect(() => {
    // Generate forms for members with startOpen option that don't have any info added yet
    const rolesWithStartOpen = roles.filter((role: Role) => {
      return role.displayOptions.startOpen && !householdInfo[role.type];
    });
    for (const roleInfo of rolesWithStartOpen){
      householdDispatch({
        type: "add",
        payload: initializeMemberInfo(roleInfo) as HouseholdMember,
      });
    }
  }, []);

  const householdCount = Object.values(householdValidState).reduce((total: number, role: HouseholdMember[]) => {
    return total + role.length;
  }, 0);

  const previousValidState = useRef(JSON.stringify(householdValidState));

  useEffect(() => {
    const newValidState = JSON.stringify(householdValidState);
    if (previousValidState.current !== newValidState) {
      previousValidState.current = newValidState;
      props.setInfoKey(
        props["Target Field"]!,
        newValidState !== "{}" ? newValidState : "",
        true,
        false,
      );
      props.setInfoKey(
        props["Target Field"] + "_count",
        householdCount === 0 ? "" : JSON.stringify(householdCount),
        true,
        false
      );
    }
  }, [householdValidState]);

  const label =
    householdCount === 1
      ? memberCountLabel?.singular[context.lang] || L.questions.household.household_member
      : memberCountLabel?.plural[context.lang] || L.questions.household.household_members;
  const householdCountText = `${householdCount} ${label}`;

  const initializeMemberInfo = useCallback((roleInfo: Role): HouseholdMember => {
    // Initialize memberInfo object with all required keys for this applicant
    // Empty string values represent info to be collected
    const memberInfo: HouseholdMember = {
      id: v4(),
      roleType: roleInfo.type,
    };
    const {
      collectBirthdate,
      dependence,
      customQuestions,
      hideName,
      requireFullName
    } = roleInfo.dataCollectOptions || {};

    if (!hideName) {
      if (requireFullName === 'Split first and last') {
        memberInfo.first_name = "";
        memberInfo.last_name = "";
      } else {
        memberInfo.name = "";
      }
      
    }
    if (collectBirthdate === "full") {
      memberInfo.birth_date = "";
    }
    if (collectBirthdate === "year_only") {
      memberInfo.birth_year = "";
    }
    if (dependence) {
      if (dependence === "collect") {
        memberInfo.dependence = "";
      } else {
        memberInfo.dependence = dependence.preset;
      }
    }
    if (customQuestions) {
      for (const customQuestion of customQuestions) {
        memberInfo[customQuestion.key] = "";
      }
    }
    return memberInfo;
  }, []);

  const referenceDateMs = getReferenceDateMs(referenceDate, props.info);

  useEffect(() => {
    if (referenceDateMs === 0) {
      toast({
        description: `Household calcuator reference date is invalid: ${referenceDate}`,
        variant: 'error'
      });
    }
  }, [referenceDateMs]);

  return (
    <div className="flex flex-col gap-3">
      <div className="w-full border bg-white shadow rounded-lg px-2.5 text-lg ">
        <div className="sm:mx-3 mt-4 mb-3 pb-2 text-center" style={{ borderBottom: "1px solid lightgrey" }}>{title?.[context.lang] || L.questions.household.your_household}</div>
        {showYou && (
          <MemberDisplayGroup
            label={L.questions.income.person.you}
            members={[
              {
                id: "you",
                roleType: "system_you",
                name: props.info[showYou?.nameField],
                birth_date: showYou?.birthdateField ? props.info[showYou.birthdateField] : undefined,
              },
            ]}
          />
        )}

        {Object.keys(householdFormState).filter((roleType: string) => roleType !== 'system_you').map((roleType: string) => (
          <MemberFormGroup
            info={props.info}
            key={roleType}
            referenceDateMs={referenceDateMs}
            roleInfo={roles.find((role: Role) => role.type === roleType) || { type: '', label: { singular: {} }, dataCollectOptions: {}, displayOptions: {} } as Role}
            membersWithForm={householdFormState[roleType] || []}
            membersSaved={householdInfo[roleType] || []}
            householdDispatch={householdDispatch}
            initializeMemberInfo={initializeMemberInfo}
          />
        ))}

        {/* Buttons for member types with no forms */}
        <div className="flex flex-wrap gap-2 my-2 sm:mx-3 ">
          {roles
            .filter((roleInfo: Role) => !householdFormState[roleInfo.type]?.length)
            .map((roleInfo: Role) => (
              <button
                key={roleInfo.type}
                className="w-full sm:w-auto flex items-center justify-center gap-1.5 text-white border border-gray-300 bg-indigo-600 hover:bg-indigo-700 shadow-sm text-sm font-medium rounded-md px-4 py-2 min-h-10"
                onClick={() => householdDispatch({
                  type: "add",
                  payload: initializeMemberInfo(roleInfo) as HouseholdMember,
                })}
              >
                + {L.questions.household.add} {roleInfo.label.singular[context.lang]}
              </button>
            ))
          }
        </div>
        {(showYou && householdCount === 1 && !noOthers) && <div className="flex flex-wrap gap-2 my-2 sm:mx-3 ">
          <button
            className="w-full sm:w-auto flex items-center justify-center gap-1.5 text-white border border-gray-300 bg-indigo-600 hover:bg-indigo-700 shadow-sm text-sm font-medium rounded-md px-4 py-2 min-h-10"
            onClick={() => setNoOthers(true)}
          >
            No others
          </button>
        </div>
        }

        <div className="mt-3 sm:mx-3 pt-3 pb-4 font-bold text-lg text-gray-700" style={{ borderTop: "1px solid lightgrey"}}>
          {householdCountText}
        </div>
      </div>
    </div>
  );
}
