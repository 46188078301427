import { Button, IconProps, Modal, Tooltip } from "@aidkitorg/component-library";
import { DashboardComponent, Explore, Notification, Payment, Query, SurveyComponent } from "@aidkitorg/types/lib/survey";
import { Conditional, findFields } from "@aidkitorg/types/lib/translation/expr_to_sql";
import { ChangeSet, State, UserActivity } from "@aidkitorg/typesheets/lib/collab";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React, { ReactElement, Suspense, useMemo, useRef, useState } from "react";

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

export default function ExploreLink({ query, className, icon }: { className?: string, icon?: ReactElement<IconProps>, query: Explore }) {
  return <div className={className ?? "text-sm align-super"}>
    <a target="_blank" href={`/explore?run=yes#${btoa(JSON.stringify(query))}`}>
      {icon || <ArrowTopRightOnSquareIcon className={"w-5 h-5"} />}
    </a>
  </div>
}

export type DistroPreviewModalProps = {
  onDistroChanged?(v: any, collabState: State, events: ChangeSet, expanded: Record<string, any> | null, myActivity?: UserActivity | undefined): any,
  open: boolean,
  onClose(value: boolean): void,
  title?: string,
  query: Explore | SurveyComponent | DashboardComponent
};

export function DistroPreviewModal({ query, open, onClose, title = "Preview", onDistroChanged }: DistroPreviewModalProps) {
  const distroRef = useRef<React.ComponentRef<typeof Distro>>(null);

  return <Modal open={open} title={title} size="fullscreen" onClose={onClose}>
    <Suspense>
      <div className="bg-white">
        <Distro
          ref={distroRef}
          onChange={onDistroChanged}
          value={query}
          types='src/survey.ts'
          name='Explore'
        />
      </div>
    </Suspense>
  </Modal>
}

export function DistroExprLink({ name, expr, extraTargetFields }: { name?: string, expr: Query, extraTargetFields?: string[] }) {

  const query: Explore = useMemo(() => {
    const nameInfo = name ? {
      title: { en: name },
      download: {
        filename: name
      }
    } : {};

    if (expr.kind === 'Click') {
      let fields: string[] = findFields(expr.expr);
      if (!fields.length) {
        fields = ['legal_name'];
      }
      fields = fields.concat(extraTargetFields || []);

      return {
        query: {
          kind: 'Applicant Table',
          filter: expr,
          columns: fields.map(f => ({ kind: 'Field', field: f })),
          ...nameInfo
        }
      };
    } else {
      return {
        query: {
          kind: 'Custom Query',
          sql: expr.sql,
          visualization: {
            kind: 'Table',
            ...nameInfo
          }
        }
      };
    }
  }, [expr, name]);

  return <ExploreLink query={query} />
}
