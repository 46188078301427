import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import bsCustomFileInput from "bs-custom-file-input";

import { useAPIPost, useAPIUpload, usePost } from "../API";
import { Attachment } from "../Questions/Attachment";

import ListGroup from "react-bootstrap/ListGroup";
import InterfaceContext, { ConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import TranslateWrapper from "../Translator";
import { blockify, renderLinks, safeParse } from "../Util";
import { Note } from "aidkit/lib/program/note";
import { Button, Card, CardHeader, CardBody, CardFooter, FileInput, Modal, TextArea, useToast } from "@aidkitorg/component-library";
import { BadgeVariant } from "@aidkitorg/component-library/dist/types/src/Badge";

import * as Sentry from "@sentry/react";

type NotesProps = {
  uid: string;
  canRequestChanges: boolean;
  canDeleteNotes: boolean;
  internalAudit?: boolean;
  unsubmittedApp?: boolean;
};

function Notes(props: NotesProps) {
  const L = useLocalizedStrings();
  const { toast } = useToast();

  const [selectedFiles, setSelectedFiles] = useState([] as File[]);
  const addNote = usePost('/applicant/add_note');
  const addNoteWithAttachment = usePost('/applicant/add_note_with_attachment');
  const uploadURL = usePost('/document/upload_url');
  const upload = useAPIUpload('');
  const getNotes = usePost('/applicant/get_notes');
  const addContactNote = usePost('/support/create_note');
  const getContactNotes = usePost('/support/get_notes');

  const [showNotifyReviewer, setShowNotifyReviewer] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const [message, setMessage] = useState("");

  const [notes, setNotes] = useState<{ data: Note[] } | undefined>();
  const refreshNotes = async (params: { applicant: string, isInternalAudit?: boolean }) => {
    let getNotesResponse;
    if (props.unsubmittedApp) {
      getNotesResponse = await getContactNotes({
        contact: params.applicant
      });
    } else {
      getNotesResponse = await getNotes(params);
    }

    if (!('error' in getNotesResponse)) {
      setNotes(getNotesResponse);
    }
  }

  const configuration = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);

  async function saveNote() {
    if (message.length === 0) {
      alert(L.applicant.notes.please_enter_a_message);
      return;
    }
    if (configuration.enable_notes_to_reviewers === 'true') {
      setShowNotifyReviewer(true);
    } else {
      actuallySaveNote(false);
    }
  }

  async function actuallySaveNote(notify: boolean) {
    setShowNotifyReviewer(false);

    if (props.unsubmittedApp) {
      const result = await addContactNote({
        contact: props.uid,
        note: message,
      });
    } else if (selectedFiles.length > 0) {
      let result;
      let savedPaths = [];
      for (const file of selectedFiles) {
        const uploadUrlResponse = await uploadURL({
          path: file.name,
          length: file.size,
        });
        if (!('error' in uploadUrlResponse)) {
          // Actually upload file
          await upload([file], {}, uploadUrlResponse.uploadURL, "PUT");
          savedPaths.push(uploadUrlResponse.savedPath);
        }
      }
      result = await addNoteWithAttachment({
        applicant: props.uid,
        message: message,
        savedPaths: savedPaths,
        notify: notify,
        isInternalAudit: props.internalAudit,
      });
    } else {
      await addNote({ applicant: props.uid, message, notify, ...(props.internalAudit && { isInternalAudit: true }) });
    }
    setMessage("");
    setSelectedFiles([]);
    await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
  }

  // Deprecated Request Changes functionality
  // const requestChange = usePost('/applicant/add_note_and_request_changes');
  // async function doRequestChange() {
  //   await requestChange({ applicant: props.uid, message: message });
  //   await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
  //   setMessage("");
  // }

  const resolveRequest = usePost('/applicant/resolve_note');
  async function doResolveRequest(note_id: string) {
    await resolveRequest({ id: note_id });
    await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
  }

  const removeSelectedFile = (f: File) => {
    const files = [...selectedFiles].filter((file) => file.name !== f.name)
    setSelectedFiles(files)
  }

  useEffect(() => {
    (async () => {
      await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
    })();
  }, [props.uid, props.internalAudit]);

  /* For attachments with uploads */

  useEffect(() => {
    bsCustomFileInput.init();
  });

  /* For deleting notes */

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<Note['id']>()

  const delete_note = useAPIPost("/note/" + noteToDelete + "/delete_note");
  const delete_contact_note = usePost("/support/delete_note");

  const onCloseDeleteModal = () => {
    setShowDeleteConfirmation(false)
    setNoteToDelete("")
  }

  async function deleteNote() {
    if (!noteToDelete) return
    
    let response;
    setIsDeleting(true)

    if (props.unsubmittedApp) {
      response = await delete_contact_note({ id: noteToDelete })
    } else {
      response = await delete_note({});
    }

    if (!response) {
      toast({
        description: L.applicant.notes.delete_error,
        variant: 'error',
      })
      Sentry.captureException("Error deleting note")
    } else if (response.status === "ok") {
      onCloseDeleteModal()
      await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit })
    } 

    setIsDeleting(false)
  }

  return (
    <div>
      <Modal
        open={showNotifyReviewer}
        title={L.applicant.notes.save_and_notify_confirmation}
        onClose={(e) => setShowNotifyReviewer(false)}
        size="lg"
      >
        <p>
          {L.applicant.notes.save_and_notify_msg}
        </p>
        <div className="flex justify-end gap-4">
          <Button variant="secondary" onClick={() => { actuallySaveNote(true) }}>{L.applicant.notes.save_without_notifying}</Button>
          <Button variant="primary" onClick={() => { actuallySaveNote(false) }}>{L.applicant.notes.save_and_notify}</Button>
        </div>
      </Modal>
      <Modal
        title="Delete note?"
        onClose={onCloseDeleteModal}
        open={showDeleteConfirmation}
      >
        <div>
          {L.applicant.notes.delete_note_msg}
          <div className="flex justify-end gap-3">
            <Button variant="secondary" onClick={() => onCloseDeleteModal()}>{L.applicant.notes.cancel}</Button>
            <Button variant="primary" isDisabled={isDeleting} onClick={async () => await deleteNote()}>{L.applicant.notes.confirm_delete}</Button>
          </div>
        </div>
      </Modal >
      <TextArea
        value={message}
        className="py-3"
        onChange={(e) => setMessage(e.target.value)}
        label={L.applicant.notes.add_note}
      />
      <div className="flex flex-wrap justify-between pb-2">
        <FileInput
          className="py-2"
          files={selectedFiles}
          onChange={(e) =>
            setSelectedFiles(Array.from(e.target.files || []))}
          onDeleteHandler={(f: File) => removeSelectedFile(f)}
        />
        <div className="ml-auto py-2">
          <Button variant="primary" onClick={saveNote} isDisabled={message.length === 0}>{L.applicant.notes.add_note}</Button>
        </div>
      </div>
      {/* Deprecated Request Changes functionality */}
      {/* <InputGroup className="mt-2 d-flex justify-content-end">
          {!addingAttachment && props.canRequestChanges && (
            <Button variant="danger" onClick={doRequestChange}>
              {L.applicant.notes.request_change}
            </Button>
          )}
        </InputGroup.Append>
      </InputGroup> */}
      <ListGroup>
        {notes &&
          notes.data &&
          notes.data.map((n: Note) => {
            const metadata = safeParse(n.metadata || '{}') as Record<string, any>;
            let badgeText = undefined
            let badgeVariant: BadgeVariant | undefined = undefined

            if (n.action_required || n.requested_change) {
              if (n.resolved || n.action_completed) {
                badgeText = L.applicant.notes.resolved
                badgeVariant = "success"
              } else {
                badgeText = L.applicant.notes.changes_requested
                badgeVariant = "error"
              }
            }

            return (
              <Card className="p-4">
                <CardHeader
                  badgeText={badgeText}
                  badgeVariant={badgeVariant}
                  actionButton={props.canDeleteNotes ? (
                    <Button
                      variant="secondary"
                      onClick={
                        () => {
                          setShowDeleteConfirmation(true);
                          setNoteToDelete(n.id || "")
                        }}
                    >{L.applicant.notes.delete}</Button>
                  ) : undefined}
                />
                <CardBody>
                  <div className="pt-2">
                    {React.createElement(TranslateWrapper, {
                      id: `note-${n.id}`,
                      desired_lang: context.lang,
                      body: blockify(n.message || '', renderLinks),
                      metadata
                    })}
                  </div>
                  <div className="list-none">
                    {metadata && metadata['attachments']?.map((url: string) => {
                      return <div className="py-2"><Attachment key={url} url={url} /></div>;
                    })}
                  </div>
                </CardBody>
                <CardFooter className="pt-3">
                  <div className="flex flex-wrap justify-between">
                    <div className="mt-auto text-sm">
                      <div>{n.author}</div>
                      <div> {moment.unix(n.created_at).calendar()}</div>
                    </div>
                    <div className="mt-auto flex gap-4">
                      {((n.action_required && !n.action_completed) || (n.requested_change && !n.resolved)) &&
                        (<Button
                          onClick={() => doResolveRequest(n.id!)}
                          variant="primary">
                          {L.applicant.notes.mark_resolved}
                        </Button>)
                      }
                    </div>
                  </div>
                </CardFooter>
              </Card>
            );
          })}
      </ListGroup>
    </div >
  );
}

export default Notes;
