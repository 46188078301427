import React, { useState } from "react";
import { QuestionProps } from "./Props";
import { usePost } from "../API";
import { safeParse } from "@aidkitorg/roboscreener/lib/util/json";
import { useToast } from "@aidkitorg/component-library";
import { useLocalizedStrings } from "../Localization";

export function InlineNotification(props: QuestionProps) {
  const issueNotif = usePost('/program/admin/issue_notification');
  const [sending, setSending] = useState(false);
  const { name } = safeParse(props.Metadata || '{}');
  const { toast } = useToast();
  const L = useLocalizedStrings();

  async function sendNotification() {
    // Using a callback here guarantees that multiple clicks will not cause multiple API calls
    // because React automatically queues the set requests and executes them sequentially.
    setSending((currentSending) => {
      if (currentSending) {
        console.log("Already clicked");
        return currentSending;
      }

      if (!props['Target Field']) {
        toast({
          description: "Inline notification missing target field",
          variant: 'error'
        });
        return currentSending;
      }

      if (!props.uid) {
        toast({
          description: "Inline notification missing applicant uid",
          variant: 'error'
        });
        return currentSending;
      }

      (async () => {
        await issueNotif({
          target_field: props['Target Field']!,
          applicant: props.uid!,
        });
        setSending(false);
      })();

      return true;
    });
  }

  const sentDisplayMessage =
    sending
      ? L.questions.notification.sending_notification
      : (props['Target Field'] && (props.info[props['Target Field']] || '').toLowerCase().startsWith('error'))
        ? L.questions.notification.error_sending_notification
        : L.questions.notification.notification_sent;

  return (
    <div className='w-full'>
      <h3>{name}</h3>
      {(props.info[props['Target Field']!] || sending)
        ? <div className='my-1.5'>{sentDisplayMessage}</div>
        : (
          <button
            onClick={sendNotification}
            type='button'
            disabled={sending}
            className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 my-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            {'Send ' + (props['Target Field']?.endsWith('_sms') ? 'SMS' : 'Email')}
          </button>
        )
      }
    </div>
  );
}
