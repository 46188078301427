"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Flag = void 0;
var async_1 = require("../../util/async");
var sets_1 = require("../../util/sets");
var db_1 = require("../../util/db");
var util_1 = require("./util");
var json_1 = require("../../util/json");
var neverthrow_1 = require("neverthrow");
var runtime_1 = require("@pgtyped/runtime");
var survey_loader_1 = require("../survey_loader");
exports.Flag = {
    getSlugs: function (params) {
        var e_1, _a, e_2, _b, e_3, _c;
        var flagMetadata = (0, util_1.formatSurveyFlagMetadata)(params.infoDefs[params.targetField]['metadata']);
        var dependencies = new Set();
        // console.log("FLAG SLUGS: ", params.deploymentKey)
        if (flagMetadata.query) {
            try {
                for (var _d = __values(Object.keys(flagMetadata.query)), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var deploymentKey = _e.value;
                    flagMetadata.query[deploymentKey].depends_on.forEach(dependencies.add, dependencies);
                    flagMetadata.query[deploymentKey].params.forEach(dependencies.add, dependencies);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (flagMetadata.unique) {
            try {
                for (var _f = __values(Object.keys(flagMetadata.unique)), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var deploymentKey = _g.value;
                    flagMetadata.unique[deploymentKey].forEach(dependencies.add, dependencies);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        if (flagMetadata.fuzzy_unique) {
            try {
                for (var _h = __values(Object.keys(flagMetadata.fuzzy_unique)), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var deploymentKey = _j.value;
                    flagMetadata.fuzzy_unique[deploymentKey].forEach(dependencies.add, dependencies);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        // console.debug(`Flag slugs called for targetField ${params.targetField}, returning: ${dependsOn}`);
        // Return the flag target field itself as a slug because flag is a derived field.
        return (0, neverthrow_1.ok)({ 'slugs': ['_comment', params.targetField], dependencies: __spreadArray([], __read(dependencies), false) });
    },
    compute: function (params) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var allUpdates, flag, updates;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        allUpdates = {};
                        if (params.targetField.endsWith('_comment'))
                            return [2 /*return*/, (0, neverthrow_1.ok)(allUpdates)];
                        if (process.env.env === 'test')
                            return [2 /*return*/, (0, neverthrow_1.ok)(allUpdates)];
                        if (!params.conn)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        flag = params.infoDefs[params.targetField];
                        // If a flag is async and the computeOptions doesn't explicitly specify we should runAsynComputations=true, skip this flag processing.
                        // The only exception is if the target field was marked as a force compute field, then we will allow processing.
                        if ((flag.metadata && (0, json_1.safeParse)(flag.metadata || '{}').async && !((_a = params.computeOptions) === null || _a === void 0 ? void 0 : _a.runAsyncComputations))
                            && !((_c = (_b = params.computeOptions) === null || _b === void 0 ? void 0 : _b.forceComputeFields) === null || _c === void 0 ? void 0 : _c.includes(params.targetField))) {
                            console.debug("Skipping flag=".concat(flag.key, " becuase it is an async flag and runAsyncComputations is not enabled"));
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        return [4 /*yield*/, processFlag(params.deploymentKey, flag, params.newInfoKeys)];
                    case 1:
                        updates = _d.sent();
                        return [2 /*return*/, updates];
                }
            });
        });
    }
};
/** Adds any matches from the queryObject to pendingMatches set. */
function addQueryMatches(thisDeployment, pendingMatches, infoKeys, queryObject) {
    return __awaiter(this, void 0, void 0, function () {
        var queryMatches;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryMatches = new Set();
                    return [4 /*yield*/, (0, async_1.asyncForEach)(Object.keys(queryObject), function (deploymentKey) { return __awaiter(_this, void 0, void 0, function () {
                            var query, queryDeployment, params, queryParams, sql, index, params_1, params_1_1, param, attempts, queryResponse, _a, _b, row;
                            var e_4, _c, e_5, _d;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        query = queryObject[deploymentKey];
                                        queryDeployment = deploymentKey === '.' ? thisDeployment : deploymentKey;
                                        params = query['params'] || [];
                                        queryParams = [];
                                        sql = query['sql'] || '';
                                        index = 0;
                                        try {
                                            for (params_1 = __values(params), params_1_1 = params_1.next(); !params_1_1.done; params_1_1 = params_1.next()) {
                                                param = params_1_1.value;
                                                queryParams.push(infoKeys[param] || '');
                                                attempts = 0;
                                                while (sql.indexOf('$' + param) !== -1 && attempts < 10) {
                                                    sql = sql.replace('$' + param, '$$' + (index + 1).toString());
                                                    attempts++;
                                                }
                                                index++;
                                            }
                                        }
                                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                                        finally {
                                            try {
                                                if (params_1_1 && !params_1_1.done && (_c = params_1.return)) _c.call(params_1);
                                            }
                                            finally { if (e_4) throw e_4.error; }
                                        }
                                        console.debug(thisDeployment, "Flag SQL for Query: ", sql);
                                        return [4 /*yield*/, (0, db_1.unsafeReadOnlyQuery)(sql, queryParams, queryDeployment)];
                                    case 1:
                                        queryResponse = _e.sent();
                                        if (queryResponse.isErr()) {
                                            console.log(thisDeployment, "Error querying for Flag: ", queryResponse.error);
                                            return [2 /*return*/];
                                        }
                                        console.debug(thisDeployment, "Flag Query Response: ", queryResponse.value);
                                        try {
                                            for (_a = __values(queryResponse.value), _b = _a.next(); !_b.done; _b = _a.next()) {
                                                row = _b.value;
                                                if (!row['uid'])
                                                    continue;
                                                queryMatches.add(queryDeployment + ':' + row['uid']);
                                            }
                                        }
                                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                                        finally {
                                            try {
                                                if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                                            }
                                            finally { if (e_5) throw e_5.error; }
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _a.sent();
                    // If pending Matches has things in it, remove any that are not in the queryMatches set. 
                    // Else, if pending matches is empty, add all query matches to it, 
                    (0, sets_1.intersectSets)(pendingMatches, queryMatches, true);
                    return [2 /*return*/];
            }
        });
    });
}
function addUniqueMatches(thisDeployment, pendingMatches, infoKeys, uniqueObject, useFuzzyMatching, previousQueriesRun // Whether a previous query has already been run
) {
    return __awaiter(this, void 0, void 0, function () {
        var uniqueMatches, fuzzy, unique, query;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    uniqueMatches = new Set();
                    fuzzy = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        SELECT a.uid, pi.value as program_identity FROM \n            (select applicant.uid FROM Applicant\n                JOIN ApplicantInfo ON ApplicantInfo.applicant = Applicant.uid \n                AND latest = TRUE and key = $key and value % $value::text\n                WHERE Applicant.uid != $uid\n                AND (hidden is null or hidden = false)\n            ) a \n            left join applicantinfo pi on pi.applicant=a.uid and pi.latest=true \n                and pi.key = 'program_identity' and pi.value <> ''\n    "], ["\n        SELECT a.uid, pi.value as program_identity FROM \n            (select applicant.uid FROM Applicant\n                JOIN ApplicantInfo ON ApplicantInfo.applicant = Applicant.uid \n                AND latest = TRUE and key = $key and value % $value::text\n                WHERE Applicant.uid != $uid\n                AND (hidden is null or hidden = false)\n            ) a \n            left join applicantinfo pi on pi.applicant=a.uid and pi.latest=true \n                and pi.key = 'program_identity' and pi.value <> ''\n    "])));
                    unique = (0, runtime_1.sql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        SELECT a.uid, pi.value as program_identity FROM \n            (SELECT applicant.uid FROM applicant\n                JOIN ApplicantInfo ON ApplicantInfo.applicant = Applicant.uid \n                AND latest = TRUE and key = $key and value = $value\n                WHERE Applicant.uid != $uid\n                AND (hidden is null or hidden = false)\n            ) a\n            left join applicantinfo pi on pi.applicant=a.uid and pi.latest=true \n                and pi.key = 'program_identity' and pi.value <> ''\n    "], ["\n        SELECT a.uid, pi.value as program_identity FROM \n            (SELECT applicant.uid FROM applicant\n                JOIN ApplicantInfo ON ApplicantInfo.applicant = Applicant.uid \n                AND latest = TRUE and key = $key and value = $value\n                WHERE Applicant.uid != $uid\n                AND (hidden is null or hidden = false)\n            ) a\n            left join applicantinfo pi on pi.applicant=a.uid and pi.latest=true \n                and pi.key = 'program_identity' and pi.value <> ''\n    "])));
                    query = useFuzzyMatching ? fuzzy : unique;
                    return [4 /*yield*/, (0, async_1.asyncForEach)(Object.keys(uniqueObject), function (deploymentKey) { return __awaiter(_this, void 0, void 0, function () {
                            var queryDeployment, queryDeploymentConnAttempt, queryDeploymentConn, deploymentMatches, queriedAtLeastOne, programIdentityIsAField;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        queryDeployment = deploymentKey === '.' ? thisDeployment : deploymentKey;
                                        return [4 /*yield*/, (0, db_1.getReadOnlyDbConn)(queryDeployment)];
                                    case 1:
                                        queryDeploymentConnAttempt = _a.sent();
                                        if (queryDeploymentConnAttempt.isErr()) {
                                            console.warn("Cannot check deployment ".concat(queryDeployment, " for flag, error connecting to the deployment database."));
                                            return [2 /*return*/];
                                        }
                                        queryDeploymentConn = queryDeploymentConnAttempt.value;
                                        deploymentMatches = new Set();
                                        queriedAtLeastOne = false;
                                        programIdentityIsAField = uniqueObject[deploymentKey].includes('program_identity');
                                        return [4 /*yield*/, (0, async_1.asyncForEach)(uniqueObject[deploymentKey], function (field) { return __awaiter(_this, void 0, void 0, function () {
                                                var rows, queryMatches, rows_1, rows_1_1, row;
                                                var e_6, _a;
                                                return __generator(this, function (_b) {
                                                    switch (_b.label) {
                                                        case 0: return [4 /*yield*/, query.run({ 'key': field, 'value': infoKeys[field], 'uid': infoKeys['uid'] }, queryDeploymentConn)];
                                                        case 1:
                                                            rows = _b.sent();
                                                            queryMatches = new Set();
                                                            try {
                                                                for (rows_1 = __values(rows), rows_1_1 = rows_1.next(); !rows_1_1.done; rows_1_1 = rows_1.next()) {
                                                                    row = rows_1_1.value;
                                                                    // Dont add it if their Program Identity exists and is equal to ours:
                                                                    // unless one of the params of the flag is program_identity
                                                                    if (!programIdentityIsAField && row['program_identity'] && row['program_identity'] === infoKeys['program_identity']) {
                                                                        continue;
                                                                    }
                                                                    // Otherwise, Add it
                                                                    queryMatches.add(queryDeployment + ':' + row['uid']);
                                                                }
                                                            }
                                                            catch (e_6_1) { e_6 = { error: e_6_1 }; }
                                                            finally {
                                                                try {
                                                                    if (rows_1_1 && !rows_1_1.done && (_a = rows_1.return)) _a.call(rows_1);
                                                                }
                                                                finally { if (e_6) throw e_6.error; }
                                                            }
                                                            // console.log("Intersecting field " + field, queryMatches);
                                                            if (queriedAtLeastOne) {
                                                                (0, sets_1.intersectSets)(deploymentMatches, queryMatches, false);
                                                            }
                                                            else {
                                                                // Fill if empty
                                                                (0, sets_1.intersectSets)(deploymentMatches, queryMatches, true);
                                                                queriedAtLeastOne = true;
                                                            }
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })];
                                    case 2:
                                        _a.sent();
                                        (0, sets_1.unionSets)(uniqueMatches, deploymentMatches);
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _a.sent();
                    // console.debug("IntersectingSets and Filling... for pendingMatches, uniqueMatches: ", pendingMatches, uniqueMatches);
                    (0, sets_1.intersectSets)(pendingMatches, uniqueMatches, !previousQueriesRun);
                    return [2 /*return*/];
            }
        });
    });
}
function pruneFlag(thisProgram, flagMetadata) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var programsToCheck, flagMetadataKeys, accessPermissions, _loop_1, programsToCheck_1, programsToCheck_1_1, program, e_7_1, checkFieldsAllowed, _loop_2, flagMetadataKeys_1, flagMetadataKeys_1_1, metadataKey;
        var e_7, _e, e_8, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    programsToCheck = new Set();
                    flagMetadataKeys = ['unique', 'fuzzy_unique', 'query', 'required_info'];
                    // Collect set of other/target programs that will be checked for permission to have their data compared by this flag
                    flagMetadataKeys.forEach(function (metadataKey) {
                        var _a;
                        return Object.keys((_a = flagMetadata[metadataKey]) !== null && _a !== void 0 ? _a : {})
                            .filter(function (programName) { return programName !== '.'; }) // ignore references to own program
                            .map(function (program) { return programsToCheck.add(program); });
                    });
                    accessPermissions = {};
                    _loop_1 = function (program) {
                        var targetSurvey, _h;
                        return __generator(this, function (_j) {
                            switch (_j.label) {
                                case 0:
                                    _h = json_1.safeParse;
                                    return [4 /*yield*/, (0, survey_loader_1.getSurveyDefinition)("dso-".concat(program, "-entireprogram"))];
                                case 1:
                                    targetSurvey = _h.apply(void 0, [_j.sent()]);
                                    (((_c = (_b = (_a = targetSurvey.config) === null || _a === void 0 ? void 0 : _a.fraud) === null || _b === void 0 ? void 0 : _b.duplicateDataSharing) === null || _c === void 0 ? void 0 : _c.shareableData) || [])
                                        .forEach(function (dataRule) {
                                        var _a;
                                        if (dataRule.programsWithAccess.includes(thisProgram)) {
                                            (_a = accessPermissions[program]) !== null && _a !== void 0 ? _a : (accessPermissions[program] = new Set());
                                            dataRule.fields.map(function (field) { return accessPermissions[program].add(field); });
                                        }
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 6, 7, 8]);
                    programsToCheck_1 = __values(programsToCheck), programsToCheck_1_1 = programsToCheck_1.next();
                    _g.label = 2;
                case 2:
                    if (!!programsToCheck_1_1.done) return [3 /*break*/, 5];
                    program = programsToCheck_1_1.value;
                    return [5 /*yield**/, _loop_1(program)];
                case 3:
                    _g.sent();
                    _g.label = 4;
                case 4:
                    programsToCheck_1_1 = programsToCheck_1.next();
                    return [3 /*break*/, 2];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_7_1 = _g.sent();
                    e_7 = { error: e_7_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (programsToCheck_1_1 && !programsToCheck_1_1.done && (_e = programsToCheck_1.return)) _e.call(programsToCheck_1);
                    }
                    finally { if (e_7) throw e_7.error; }
                    return [7 /*endfinally*/];
                case 8:
                    checkFieldsAllowed = function (fields, sourceProgram, targetProgram, flagType) {
                        var e_9, _a;
                        var _b;
                        try {
                            for (var fields_1 = __values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                                var field = fields_1_1.value;
                                if (!((_b = accessPermissions[targetProgram]) === null || _b === void 0 ? void 0 : _b.has(field))) {
                                    console.log("Flag removed. Type: ".concat(flagType, " - Requested fields: ").concat(fields, " from program: ").concat(targetProgram, " are not permitted to be shared with this program: ").concat(sourceProgram));
                                    return false;
                                }
                            }
                        }
                        catch (e_9_1) { e_9 = { error: e_9_1 }; }
                        finally {
                            try {
                                if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
                            }
                            finally { if (e_9) throw e_9.error; }
                        }
                        return true;
                    };
                    _loop_2 = function (metadataKey) {
                        Object.keys((_d = flagMetadata[metadataKey]) !== null && _d !== void 0 ? _d : {}).filter(function (programName) { return (programName !== '.' && programName !== thisProgram); }).forEach(function (otherProgram) {
                            var _a;
                            var flagAllowed = true;
                            switch (metadataKey) {
                                case 'query':
                                    console.warn("Flag removed. Type: ".concat(metadataKey, " - Cross-program query flags are not allowed. This program: ").concat(thisProgram, " other program: ").concat(otherProgram, " flag query starts with: ").concat((((_a = flagMetadata['query'][otherProgram]) === null || _a === void 0 ? void 0 : _a.sql) || '').substring(0, 100), "..."));
                                    // Query flags are not allowed to be used cross program due to the challenge of limiting their scope
                                    flagAllowed = false;
                                    break;
                                case 'required_info':
                                    flagAllowed = checkFieldsAllowed(Object.keys(flagMetadata[metadataKey][otherProgram]), thisProgram, otherProgram, metadataKey);
                                    break;
                                case 'unique':
                                case 'fuzzy_unique':
                                    // Confirm all requested fields for this flag were allowed by the target program
                                    flagAllowed = checkFieldsAllowed(flagMetadata[metadataKey][otherProgram], thisProgram, otherProgram, metadataKey);
                                    break;
                                default:
                                    // We should never reach here.
                                    console.error("Error: Unknown flag type encountered: ".concat(metadataKey, " This program: ").concat(thisProgram, " other program: ").concat(otherProgram));
                            }
                            // Remove this flag if its granted permissions were not sufficient
                            if (!flagAllowed) {
                                delete flagMetadata[metadataKey][otherProgram];
                            }
                        });
                    };
                    try {
                        // Modify flag metadata to match actual permissions set by other prorgams
                        for (flagMetadataKeys_1 = __values(flagMetadataKeys), flagMetadataKeys_1_1 = flagMetadataKeys_1.next(); !flagMetadataKeys_1_1.done; flagMetadataKeys_1_1 = flagMetadataKeys_1.next()) {
                            metadataKey = flagMetadataKeys_1_1.value;
                            _loop_2(metadataKey);
                        }
                    }
                    catch (e_8_1) { e_8 = { error: e_8_1 }; }
                    finally {
                        try {
                            if (flagMetadataKeys_1_1 && !flagMetadataKeys_1_1.done && (_f = flagMetadataKeys_1.return)) _f.call(flagMetadataKeys_1);
                        }
                        finally { if (e_8) throw e_8.error; }
                    }
                    ;
                    return [2 /*return*/, flagMetadata];
            }
        });
    });
}
function processFlag(thisDeployment, flag, infoKeys) {
    return __awaiter(this, void 0, void 0, function () {
        var metadata, pendingMatches, someQueriesRun, requiredInfo, toRemove, hasRequiredInfo, updates, uid, addOrUpdateKey, savedMatchesToPropagate, previousRelated, wrappedAddOrUpdateKey, propagateMatches, getPreviousFlag, propagate_1;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pruneFlag(thisDeployment, (0, util_1.formatSurveyFlagMetadata)(flag.metadata))];
                case 1:
                    metadata = _a.sent();
                    pendingMatches = new Set();
                    someQueriesRun = false;
                    if (!metadata['query']) return [3 /*break*/, 3];
                    return [4 /*yield*/, addQueryMatches(thisDeployment, pendingMatches, infoKeys, metadata['query'])];
                case 2:
                    _a.sent();
                    someQueriesRun = true;
                    _a.label = 3;
                case 3:
                    if (!metadata['fuzzy_unique']) return [3 /*break*/, 5];
                    return [4 /*yield*/, addUniqueMatches(thisDeployment, pendingMatches, infoKeys, metadata['fuzzy_unique'], true, someQueriesRun)];
                case 4:
                    _a.sent();
                    someQueriesRun = true;
                    _a.label = 5;
                case 5:
                    if (!metadata['unique']) return [3 /*break*/, 7];
                    console.debug("This is a unique flag, adding any unique matches.. for unique obj: ", metadata['unique']);
                    return [4 /*yield*/, addUniqueMatches(thisDeployment, pendingMatches, infoKeys, metadata['unique'], false, someQueriesRun)];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7:
                    console.debug("After adding all ".concat(flag['key'], " matches, we have pendingMatches: "), pendingMatches);
                    requiredInfo = metadata['required_info'] || {};
                    toRemove = [];
                    hasRequiredInfo = (0, runtime_1.sql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            SELECT applicant, key, value \n            FROM applicantinfo \n            WHERE applicant = $uid and latest = true and key in $$keys\n        "], ["\n            SELECT applicant, key, value \n            FROM applicantinfo \n            WHERE applicant = $uid and latest = true and key in $$keys\n        "])));
                    return [4 /*yield*/, (0, async_1.asyncForEach)(__spreadArray([], __read(pendingMatches), false), function (match) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, matchDeployment, uid, configDeployment, deploymentConnAttempt, deploymentConn, requiredInfoPairs, requiredInfoKeys, keyValues, hasRequiredInfoResponse;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = __read(match.split(':'), 2), matchDeployment = _a[0], uid = _a[1];
                                        configDeployment = matchDeployment === thisDeployment ? '.' : matchDeployment;
                                        return [4 /*yield*/, (0, db_1.getReadOnlyDbConn)(configDeployment === '.' ? matchDeployment : configDeployment)];
                                    case 1:
                                        deploymentConnAttempt = _b.sent();
                                        if (deploymentConnAttempt.isErr()) {
                                            console.warn("Cannot check required info for deployment " + configDeployment);
                                            return [2 /*return*/];
                                        }
                                        deploymentConn = deploymentConnAttempt.value;
                                        // If required info is not required for the configDeployment, go next
                                        if (Object.keys(requiredInfo).indexOf(configDeployment) < 0) {
                                            return [2 /*return*/];
                                        }
                                        requiredInfoPairs = requiredInfo[configDeployment === '.' ? matchDeployment : configDeployment];
                                        requiredInfoKeys = Object.keys(requiredInfoPairs || {});
                                        if (requiredInfoKeys.length === 0)
                                            return [2 /*return*/];
                                        keyValues = {};
                                        requiredInfoKeys.forEach(function (k) {
                                            keyValues[k] = null;
                                        });
                                        return [4 /*yield*/, hasRequiredInfo.run({ uid: uid, 'keys': requiredInfoKeys }, deploymentConn)];
                                    case 2:
                                        hasRequiredInfoResponse = _b.sent();
                                        hasRequiredInfoResponse.forEach(function (row) {
                                            keyValues[row['key']] = row['value'];
                                        });
                                        Object.entries(keyValues).forEach(function (_a) {
                                            var _b = __read(_a, 2), key = _b[0], value = _b[1];
                                            if (value != requiredInfoPairs[key]) {
                                                toRemove.push(match);
                                            }
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 8:
                    _a.sent();
                    toRemove.forEach(function (match) {
                        pendingMatches.delete(match);
                    });
                    updates = {};
                    uid = infoKeys['uid'];
                    if (!uid) {
                        console.warn("Cannot process flag, uid is not defined");
                        return [2 /*return*/, (0, neverthrow_1.err)("cannot_process_without_uid")];
                    }
                    updates[uid] = {};
                    addOrUpdateKey = function (key, value, onlyIfExists) {
                        if ((onlyIfExists && infoKeys[key]) || !onlyIfExists) {
                            updates[uid][key] = value;
                        }
                    };
                    savedMatchesToPropagate = [];
                    previousRelated = [];
                    wrappedAddOrUpdateKey = addOrUpdateKey;
                    if (metadata['transitive'] && metadata['unique']) {
                        wrappedAddOrUpdateKey = function (key, value, onlyIfExists) {
                            if (key === flag['key']) {
                                savedMatchesToPropagate.push(value);
                            }
                            return addOrUpdateKey(key, value, onlyIfExists);
                        };
                        // Collect all previously related applicants before we clobber them
                        if (infoKeys[flag['key']]) {
                            previousRelated.push.apply(previousRelated, __spreadArray([], __read((0, json_1.safeParse)(infoKeys[flag['key']] || '{}')['related']), false));
                        }
                    }
                    return [4 /*yield*/, computeAndCheckStats(infoKeys, flag, pendingMatches, metadata, wrappedAddOrUpdateKey)];
                case 9:
                    _a.sent();
                    propagateMatches = [];
                    if (savedMatchesToPropagate.length) {
                        propagateMatches.push(savedMatchesToPropagate[0]);
                    }
                    getPreviousFlag = (0, runtime_1.sql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            SELECT value \n            FROM applicantinfo \n            WHERE applicant = $uid and key = $key and latest=true\n        "], ["\n            SELECT value \n            FROM applicantinfo \n            WHERE applicant = $uid and key = $key and latest=true\n        "])));
                    if (!(propagateMatches.length && metadata['unique'])) return [3 /*break*/, 12];
                    return [4 /*yield*/, (0, async_1.asyncForEach)(previousRelated, function (related) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, relatedDeployment, relatedUid, relatedDeploymentConnAttempt, relatedDeploymentConn, rows, previousFlag, previousMetadata;
                            var _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = __read(related.split(':'), 2), relatedDeployment = _a[0], relatedUid = _a[1];
                                        if (related.includes(':') && relatedDeployment !== thisDeployment) {
                                            return [2 /*return*/];
                                        }
                                        return [4 /*yield*/, (0, db_1.getReadOnlyDbConn)(relatedDeployment)];
                                    case 1:
                                        relatedDeploymentConnAttempt = _c.sent();
                                        if (relatedDeploymentConnAttempt.isErr()) {
                                            console.warn("Transitional Flag Warning: Cannot update related deployment " + relatedDeployment + ", cannot connect to db.");
                                            return [2 /*return*/];
                                        }
                                        relatedDeploymentConn = relatedDeploymentConnAttempt.value;
                                        return [4 /*yield*/, getPreviousFlag.run({ 'uid': relatedUid, 'key': flag['key'] }, relatedDeploymentConn)];
                                    case 2:
                                        rows = _c.sent();
                                        if (!rows.length)
                                            return [2 /*return*/]; // TODO: Maybe return error if this happens
                                        previousFlag = rows[0]['value'];
                                        previousMetadata = (0, json_1.safeParse)(previousFlag || '{}');
                                        // Nuke previousMetadata, filtering out the current applicant uid.
                                        previousMetadata['related'] = previousMetadata['related'].filter(function (match) { return match.split(':')[-1] !== infoKeys['uid']; });
                                        updates[relatedUid] = (_b = {}, _b[flag['key']] = JSON.stringify(previousMetadata), _b);
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 10:
                    _a.sent();
                    propagate_1 = (0, json_1.safeParse)(savedMatchesToPropagate[0] || '{}');
                    // Set all currently related applicants to the same value (minus the related applicant)
                    return [4 /*yield*/, (0, async_1.asyncForEach)(propagate_1['related'], function (related) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, relatedDeployment, relatedUid, propagateMetadata;
                            var _b;
                            return __generator(this, function (_c) {
                                _a = __read(related.split(':'), 2), relatedDeployment = _a[0], relatedUid = _a[1];
                                if (related.indexOf(':') !== -1 && relatedDeployment !== thisDeployment) {
                                    return [2 /*return*/];
                                }
                                propagateMetadata = __assign({}, propagate_1);
                                propagateMetadata['related'] = propagateMetadata['related'].filter(function (match) { return match.split(':')[-1] !== relatedUid; });
                                propagateMetadata['related'].push(thisDeployment + ":" + infoKeys['uid']);
                                updates[relatedUid] = (_b = {}, _b[flag['key']] = JSON.stringify(propagateMetadata), _b);
                                return [2 /*return*/];
                            });
                        }); })];
                case 11:
                    // Set all currently related applicants to the same value (minus the related applicant)
                    _a.sent();
                    _a.label = 12;
                case 12: return [2 /*return*/, (0, neverthrow_1.ok)(updates)];
            }
        });
    });
}
function computeAndCheckStats(infoKeys, flag, pendingMatches, metadata, addOrUpdateKey) {
    return __awaiter(this, void 0, void 0, function () {
        var maxSum, maxCount, sumTarget, applicantMatches, sum_1, update, update;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    maxSum = metadata['max_sum'];
                    maxCount = metadata['max_count'] || 1;
                    sumTarget = metadata['sum_target'] || '';
                    applicantMatches = (0, runtime_1.sql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            SELECT value \n            FROM applicantinfo\n            WHERE key = $key and latest = true and (applicant = $uid or applicant = $this)\n        "], ["\n            SELECT value \n            FROM applicantinfo\n            WHERE key = $key and latest = true and (applicant = $uid or applicant = $this)\n        "])));
                    if (!(maxSum && sumTarget && pendingMatches.size > 0)) return [3 /*break*/, 2];
                    sum_1 = 0;
                    return [4 /*yield*/, (0, async_1.asyncForEach)(__spreadArray([], __read(pendingMatches), false), function (match) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, matchDeployment, uid, deploymentConnAttempt, rows, rows_2, rows_2_1, row, val;
                            var e_10, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = __read(match.split(':'), 2), matchDeployment = _a[0], uid = _a[1];
                                        return [4 /*yield*/, (0, db_1.getReadOnlyDbConn)(matchDeployment)];
                                    case 1:
                                        deploymentConnAttempt = _c.sent();
                                        if (deploymentConnAttempt.isErr()) {
                                            console.warn("FLAG COMPUTE: Cannot access db: " + matchDeployment);
                                            return [2 /*return*/]; // Should probably notify someone but this should not happen in prod.
                                        }
                                        return [4 /*yield*/, applicantMatches.run({ 'key': sumTarget, uid: uid, 'this': infoKeys['uid'] }, deploymentConnAttempt.value)];
                                    case 2:
                                        rows = _c.sent();
                                        try {
                                            for (rows_2 = __values(rows), rows_2_1 = rows_2.next(); !rows_2_1.done; rows_2_1 = rows_2.next()) {
                                                row = rows_2_1.value;
                                                val = parseFloat(row['value'] || '0');
                                                sum_1 += val;
                                            }
                                        }
                                        catch (e_10_1) { e_10 = { error: e_10_1 }; }
                                        finally {
                                            try {
                                                if (rows_2_1 && !rows_2_1.done && (_b = rows_2.return)) _b.call(rows_2);
                                            }
                                            finally { if (e_10) throw e_10.error; }
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _a.sent();
                    if (sum_1 > maxSum) {
                        update = JSON.stringify({
                            'related': __spreadArray([], __read(pendingMatches), false),
                            'flag': 'max_sum_exceeded'
                        });
                        addOrUpdateKey(flag['key'], update);
                    }
                    _a.label = 2;
                case 2:
                    if (pendingMatches.size >= maxCount) {
                        if (!maxSum) {
                            update = JSON.stringify({
                                'related': __spreadArray([], __read(pendingMatches), false)
                            });
                            addOrUpdateKey(flag['key'], update);
                        }
                    }
                    else {
                        addOrUpdateKey(flag['key'], '', false);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
