import { useContext } from "react";
import InterfaceContext, { PublicConfigurationContext, SupportedLanguage } from "./Context";
import { Text } from "@aidkitorg/types/lib/survey"
import { STRINGS, Translations } from "@aidkitorg/i18n/lib"
import { TimeZoneId } from "./utils/time";

export type Localizer = (text?: string | Text) => string | null | undefined;

export function useLocalization(...languages: [string?]) {
  const context = useContext(InterfaceContext);

  let langIndex = 0;
  for (let i = 0; i < languages.length; i++) {
    if (context.lang === languages[i]) {
      langIndex = i;
    }
  }

  return (...strings: [string]) => {
    return strings[langIndex] || "???";
  };
}

export function useLocalizedStringsNoContext(lang: keyof typeof STRINGS): Translations {
  return STRINGS[lang];
}

export function useLocalizedStrings(): Translations {
  const context = useContext(InterfaceContext);
  return STRINGS[context.lang];
}

export const VALID_LANGS = Object.keys(STRINGS);
export const LANG_DICTS = STRINGS;

function getLangText(lang?: keyof Text, value?: string | Text): string | null | undefined {
  if(typeof value === 'string' || value === null || typeof value === 'undefined') {
    return value;
  }

  return value[lang ?? 'en'] ?? value['en'];
}

export function useLocalizer(): Localizer {
  const context = useContext(InterfaceContext);
  return (text) => getLangText(context.lang, text);
}

export function useApplicantLocalization(applicantInfo: Record<string, any>){
  let lang : keyof typeof LANG_DICTS = applicantInfo.lang || 'en';
  if(!VALID_LANGS.includes(lang)){
    console.warn(`"${lang}" is not a supported language. Using 'en' as a fallback`);
    lang = 'en';
  }
  let locale : string = applicantInfo.locale || lang;
  try {
    // @ts-ignore
    Intl.getCanonicalLocales([locale])
  }
  catch (e:any){
    console.warn(`"${locale}" is not a valid locale. Using "${lang}" as a fallback`);
    locale = lang;
  }

  return {
    lang,
    locale
  };
}

export const langToWord = (langKey: SupportedLanguage, word: 'Language' | 'Name') => {
  if (word === 'Language') {
    return { 
      'en': 'Language', 
      'es': 'Idioma', 
      'ps_AF': 'ژبه', 
      'fa_AF': 'زبان',
      'zh_CN': '语言',
      'pl': 'Język',
      'ar': 'اللغة',
      'tl': 'Wika',
      'yue': '語言',
      'am': 'ቋንቋ',
      'ko': '언어',
      'vi': 'ngôn ngữ',
      'fa': 'زبان',
      'so': 'luqadda',
      'pt': 'Língua',
      'bn': 'ভাষা',
      'ht': 'Lang',
      'cni': 'Idioma',
      'pua': 'tsirikukua',
      'mh': 'jokwe',
      'fr': 'Langue',
      'ha': 'Yaren',
      'ne': 'भाषा',
      'ru': 'язык',
      'om': 'afaan',
      'hmn': 'Lus',
      'ua': 'мова',
    }[langKey]
  }
  return { 
    'en': 'English', 
    'es': 'Español', 
    'ps_AF': 'پښتو', 
    'fa_AF': 'فارسی',
    'zh_CN': '中文',
    'pl': 'Polski',
    'ar': 'العربية',
    'tl': 'Tagalog',
    'yue': '粵語',
    'am': 'አማርኛ',
    'ko': '한국어',
    'vi': 'Tiếng Việt',
    'fa': 'فارسی',
    'so': 'Af-Soomaali',
    'pt': 'Português',
    'bn': 'বাংলা',
    'ht': 'Kreyòl Ayisyen',
    'cni': 'Asháninka',
    'pua': 'Purépecha',
    'mh': 'Kajin M̧ajeļ',
    'fr': 'Français',
    'ha': 'Hausa',
    'ne': 'नेपाली',
    'ru': 'русский',
    'om': 'Oromoo',
    'hmn': 'Hmong',
    'ua': 'українська',
  }[langKey]
}

export const supportedLanguages: Partial<Record<keyof Text, string>> = {
  'am': 'Amharic',
  'ar': 'Arabic',
  'bn': 'Bengali',
  'cni': 'Asháninka',
  'zh_CN': 'Chinese (Simplified)',
  'yue': 'Chinese (Traditional)',
  'en': 'English',
  'fa_AF': 'Dari',
  'fa': 'Persian',
  'fr': 'French',
  'ha': 'Hausa',
  'ht': 'Haitian Creole',
  'ko': 'Korean',
  'mh': 'Marshallese',
  'ne': 'Nepali',
  'pl': 'Polish',
  'ps_AF': 'Pashto',
  'pt': 'Portuguese',
  'pua': 'Purépecha',
  'es': 'Spanish',
  'so': 'Somali',
  'tl': 'Tagalog',
  'vi': 'Vietnamese',
  'ru': 'Russian',
  'om': 'Oromo',
  'hmn': 'Hmong',
  'ua': 'Ukrainian',
}

export function useLocalTimeZoneId() {
  return Intl?.DateTimeFormat ? Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneId : 'Etc/GMT' as TimeZoneId // fallback for non-supporting browsers
};

export function useProgramTimeZoneId() {
  const publicConfig = useContext(PublicConfigurationContext);
  return publicConfig.comms?.replyScheduling?.timezone as TimeZoneId;
};

// TODO implement useApplicantTimezoneId