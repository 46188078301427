import { Heading, PasswordForm, Tabs, useToast } from "@aidkitorg/component-library";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePost } from "../API";
import { ThreeColumnPage } from "../Components/ThreeColumnPage";
import { PublicConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import { SpacedSpinner } from "../Util";

export const AdminAccountSettings = () => {
  const { toast } = useToast();
  const L = useLocalizedStrings();
  const config = useContext(PublicConfigurationContext);
  const history = useHistory();

  const [changingPassword, setChangingPassword] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  
  const resetAdminPassword = usePost("/authenticate/password/admin/reset");

  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /\d/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>~`_\+\-=\[\]\\;]/;
  const specialCharLabel = (
    <div className="flex flex-col">
      <span>At least one special character</span>
      <span>{'[!@#$%^&*(),.?":{}|<>~`_\+\-=\[\]\\;]'}</span>
    </div>
  );

  const submitResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setChangingPassword(true);
    
    try {
      const formData = new FormData(e.currentTarget);
      const values = Object.fromEntries(formData.entries());
      
      const response = await resetAdminPassword({
        password: values.password.toString(),
        oldPassword: values.current_password.toString()
      });

      if (response && response.status === 'ok') {
        toast({
          title: "Update Successful",
          description: "Your password has been successfully updated.",
          variant: "success",
        })

        // Reset all the values
        setCurrentPassword('');
        setNewPassword('');
        setNewPasswordConfirm('');
      } else {
        // API errors will be handled by our main frontend API handler with toasts
        return;
      }
    } catch (e) {
      console.error(e);
      // This will only catch front-end errors
      toast({description: "Error changing password", variant: "error"})
    } finally {
      setChangingPassword(false);
    }
  }  

  const SecurityTab = (
    <div className="my-4">
      <div className="rounded border p-4 max-w-2xl">
        <span className="font-bold inline-block mb-2">{L.admin.account_settings.change_password}</span>
        <PasswordForm
          verifyCurrentPassword
          loading={changingPassword}
          value={newPassword}
          onValueChange={(e) => setNewPassword(e.target.value)}
          confirmationValue={newPasswordConfirm}
          onConfirmationValueChange={(e) => setNewPasswordConfirm(e.target.value)}
          currentPassword={currentPassword}
          onCurrentPasswordChange={(e) => setCurrentPassword(e.target.value)}
          onSubmit={submitResetPassword}
          validationRules={
            [
              { regex: /.{16,}/, label: "At least 16 characters" },
              { regex: /^.{1,63}$/, label: "Less than 64 characters" },
              { regex: uppercaseRegex, label: "At least one uppercase character" },
              { regex: lowercaseRegex, label: "At least one lowercase character" },
              { regex: numberRegex, label: "At least one number" },
              { regex: specialCharRegex, label: specialCharLabel },
            ]
          }
        />
      </div>
    </div>
  );

  const Loading = (
    <div className="flex justify-center items-center h-screen">
      <div className="sr-only">{L.applicant.loading}</div>
      <SpacedSpinner />
    </div>
  );

  const MainContent = (
    <div className="bg-gray-50 h-full">
      <div className="max-w-5xl p-8 mx-auto">
        <Heading level="h2">{L.admin.account_settings.title}</Heading>
        <Tabs
          justify="left"
          tabs={
            [
              {
                name: L.admin.account_settings.security,
                key: 'security',
                content: SecurityTab
              }
            ]
          }
        />
      </div>
    </div>
  );
  
  // Restricting account settings page only to programs with passwords enabled for now
  // because all we have is a Security section.
  // This can be removed once we have more settings to show here.
  if (config && config.adminPasswords === false) {
    history.push("/");
  }

  return (
    <ThreeColumnPage 
      main={
        config && config.adminPasswords ? MainContent : Loading
      }
    /> 
  );
};