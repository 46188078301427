import * as v0 from '@aidkitorg/types/lib/survey';
import { PermissionScope } from '@aidkitorg/types/lib/translation/permissions';
import { CompileExpressionToEnglish } from '@aidkitorg/types/lib/translation/expr_to_english';

export type SubsurveyNode = {
  type: 'Subsurvey',
  name: string,
  targetFields: string[],
  subNodes?: PathNode[],
}

export type QuestionNodeType = {
  type: 'Question',
  questionType: string,
  targetField: string,
  content?: v0.RichText | v0.Text,
  trackedObjInfo: v0.TrackedObjectInfo, // TODO: should this be optional?
  choices?: { label: v0.Text, value: string }[],
  subContent?: QuestionNodeType[],
  optional?: boolean,
  hidden?: boolean,
}

export type SectionNode = {
  type: 'Section',
  name: string,
  subNodes?: PathNode[],
}

export type ConditionalNode = {
  type: 'Condition',
  condition: string //v0.ClickQuery | v0.Query | v0.BooleanExpr | v0.Code,
  subNodesTrue?: PathNode[],
  subNodesFalse?: PathNode[],
}

export type PathNode = SubsurveyNode | QuestionNodeType | SectionNode | ConditionalNode;
 
/**
 * This function takes the entire program survey defintion and maps out all paths through it as a tree of PathNodes.
 * A "path" here is a sequence of consecutive questions with branches wherever there are conditionals. In this way,
 * A data object is created which represents the various possible paths someone could take through a given survey.
 * 
 * TODO: It might be worth only running this on a per-subsurvey basis at least some of the time instead of always
 * running it against the entire survey, if we can guarantee that the correct Subsurvey root is passed in.
 */
export function mapSubsurveyPaths(root: v0.Root, scope?: PermissionScope) {
  const subSurveys: SubsurveyNode[] = [];

  function traverse(node: any, path: PathNode[], targetFields: string[]): void {

    /**
     * Helper used a few places below to add QuestionNodes to the path and note the target fields.
     * These QuestionNodes are what will be rendered in the main view of the editor via renderSubNodeOuter()
     * and the QuestionNode React component.
     */
    function addQuestion(content?: (v0.RichText | v0.Text) & { _trackedObjInfo: v0.TrackedObjectInfo }, targetField: v0.TargetField = node.targetField) {
      if (scope && targetField && !scope.fieldScope.includes(targetField)) {
        return;
      }

      const thisQuestionAsNode: QuestionNodeType = {
        type: 'Question',
        questionType: node.kind,
        targetField,
        content: content || node.content || { en: '' },
        trackedObjInfo: content?._trackedObjInfo || node.content?._trackedObjInfo || content?.en || node.content?.en || '',
        choices: node.choices,
        subContent: [] as QuestionNodeType[],
        optional: node.optional,
        hidden: node.hidden,
      }

      // TODO: rename "choices" to something more generic.
      if (node.choices) {
        thisQuestionAsNode.subContent = node.choices.map((choice: any) => {
          return {
            type: 'Question',
            questionType: 'SubContent',
            targetField: targetField + '→' + choice.value,
            content: choice.label,
            trackedObjInfo: choice.label?._trackedObjInfo,
            optional: node.optional,
            hidden: node.hidden,
          }
        });
      }

      // If this question itself is conditional, add a conditional in the path and set this question as the true path, else just add to path
      if (node.conditionalOn) {
        path.push({ type: 'Condition', condition: CompileExpressionToEnglish(node.conditionalOn), subNodesTrue: [thisQuestionAsNode] })
      } else {
        path.push(thisQuestionAsNode);
      }
      targetFields.push(targetField);
    }

    if (typeof node === 'object') {
      if (node.kind && (typeof node.targetField === 'string' || node.kind === 'Explanation')) {
        addQuestion();
        return; // return early here because we are at a leaf
      } else if (node['kind'] === 'Likert') {
        if (Array.isArray(node.questions)) {
          for (const question of node.questions) {
            addQuestion(question.label, question.targetField);
          }
        } else {
          // This covers the LikertDataSource version of Likerts
          addQuestion(node.questions.displayField, node.questions.targetField + '_output');
        }
        return; // return early here because we are at a leaf
      }

      // Branch on Conditional Blocks to mirror paths through the survey
      if (node.kind && node.kind === 'Conditional Block') {
        // Recurse down path where condition is met
        const truePath: PathNode[] = [];
        traverse(node.components, truePath, targetFields);
        path.push({ type: 'Condition', condition: CompileExpressionToEnglish(node.conditionalOn), subNodesTrue: truePath })

        // If present, recurse down path where condition is not met
        if (node.otherwise) {
          const falsePath: PathNode[] = [];
          traverse(node.otherwise, falsePath, targetFields);
          path.push({ type: 'Condition', condition: 'otherwise', subNodesFalse: falsePath })
        }

        return; // return early here because we have traversed subnodes already
      }

      if (node.kind && node.kind === 'Subsurvey') {
        const subsurveyPath: PathNode[] = [];
        const subSurveyTargetFields: string[] = [];
        traverse(node.sections, subsurveyPath, subSurveyTargetFields);
        // Due to permissions, a given user's view of a subsurvey may have no viewable fields
        // If this is the case, we simply don't include the subsurvey in the list
        if (subSurveyTargetFields.length > 0) {
          subSurveys.push({ type: 'Subsurvey', name: node.path, subNodes: subsurveyPath, targetFields: subSurveyTargetFields });
        }
        return; // return early here because we have traversed subnodes already.
      }

      if (node.kind && node.kind === 'Section') {
        const thisSectionNode = { type: 'Section', name: node.title.en } as SectionNode;
        // If this section itself is conditional, add a conditional in the path and set this Section as the true path, else just add to path
        if (node.conditionalOn) {
          const sectionPath: PathNode[] = [];
          traverse(node.components, sectionPath, targetFields);
          path.push({ type: 'Condition', condition: CompileExpressionToEnglish(node.conditionalOn), subNodesTrue: [thisSectionNode, ...(sectionPath || [])] })
          return; // return early here because we have traversed subnodes already
        } else {
          path.push(thisSectionNode);
          // Fall through to array/Object code below (do we need array?)
        }
      }

      // Note: the Section path above falls through to this
      if (Array.isArray(node)) {
        node.forEach((subNode) => traverse(subNode, path, targetFields));
        return;
      }

      Object.values(node).forEach((subNode) => traverse(subNode, path, targetFields));
      return;
    }
    return;
  }

  const path: PathNode[] = [];
  traverse(root, path, []);
  return subSurveys;
}