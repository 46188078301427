import React, { FormEvent, useState } from "react";
import { usePost } from '../API';
import { CustomDateTimePicker } from "./DateTimePicker";
import { timezones } from '@aidkitorg/types/lib/timezones';
import { useLocalTimeZoneId, useProgramTimeZoneId } from "../Localization";
import { TimeZoneId } from "../utils/time";

const RoughlyTwoMonthsInMillis = 2 * 30 * 24 * 60 * 60 * 1000;

const toMinuteString = (d: number, timeZone?: string): string => {
  // fallback for unsupporting browsers
  if(!Intl?.DateTimeFormat){
    return new Date(d).toLocaleDateString();
  }

  const lang = navigator?.language || 'en-US';
  return [
    new Intl.DateTimeFormat(lang, { timeZone, year: 'numeric' }).format(d),
    new Intl.DateTimeFormat(lang, { timeZone, month: '2-digit' }).format(d).padStart(2, '0'),
    new Intl.DateTimeFormat(lang, { timeZone, day: '2-digit' }).format(d).padStart(2, '0'),
    new Intl.DateTimeFormat(lang, { timeZone, hourCycle: 'h23', hour: '2-digit' }).format(d).padStart(2, '0'),
    new Intl.DateTimeFormat(lang, { timeZone, minute: '2-digit' }).format(d).padStart(2, '0')
  ].join('-');
}

export default function TransactionDownload() {
  const getSpendingData = usePost('/givecard/spending');
  const [startDate, setStartDate] = useState(Date.now() - RoughlyTwoMonthsInMillis);
  const [endDate, setEndDate] = useState(Date.now());
  const [optInField, setOptInField] = useState('enrollment_consent_spending_data');
  const [delimiter, setDelimiter] = useState(',');
  const timezoneId = useProgramTimeZoneId() || useLocalTimeZoneId();

  async function fetchAndDownload(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const data = await getSpendingData({
      delimiter,
      optInField,
      from: startDate,
      to: endDate,
      asCSV: true,
      cardIdField: 'card_id'
    });

    if (data.totals) {
      const blob = new Blob([data.totals as string ?? ''], { type: 'text/csv' });
      const link = document.createElement('a');
      link.download = `givecard_transactions_${toMinuteString(startDate, timezoneId)}_to_${toMinuteString(endDate, timezoneId)}`;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return <form className="border rounded p-4 inline-table space-y-3" onSubmit={fetchAndDownload}>
    <div className="flex">
      <label className="pr-2" htmlFor="from">From:</label>
      <CustomDateTimePicker         
        id="from" 
        timeZoneId={timezoneId as TimeZoneId}
        referenceTimeZoneId="Etc/GMT"
        initialValue={startDate} 
        onChange={setStartDate}
        before={Math.min(Date.now(), endDate)} 
        after={0}/>
    </div>
    <div className="flex">
      <label className="pr-2" htmlFor="to">To:</label>
      <CustomDateTimePicker 
        id="to" 
        timeZoneId={timezoneId as TimeZoneId}
        referenceTimeZoneId="Etc/GMT"
        initialValue={endDate} 
        onChange={setEndDate}
        after={startDate}
        before={Date.now()} />
    </div>
    <div>
      <label className="pr-2" htmlFor="optInField">Opt-In Field:</label>
      <input type="text" value={optInField} className="border rounded w-full" onChange={e => setOptInField(e.currentTarget.value)} />
    </div>
    <div>
      <label className="pr-2" htmlFor="delimiter">Delimiter:</label>
      <input type="text" value={delimiter} className="font-bold text-lg text-center w-auto flex-grow border rounded" onChange={e => setDelimiter(e.currentTarget.value)} />
    </div>
    <div className="space-x-2 float-right">
      <button className="mt-3 bg-red-500 rounded-md p-2 text-white font-semibold" type="reset">Reset</button>
      <button disabled={!optInField || !delimiter} className="mt-3 bg-indigo-700 rounded-md p-2 text-white font-semibold disabled:bg-gray-400" type="submit">Download</button>
    </div>
  </form>;
}
