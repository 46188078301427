import React, { useState, useEffect, useMemo } from 'react';
import { timezones } from '@aidkitorg/types/lib/timezones';
import { classNames } from '../Util';
import { Listbox, Transition } from '@headlessui/react';
import { useLocalizedStrings } from '../Localization';
import { ClockIcon } from '@heroicons/react/24/outline';
import { getTimeZoneData, TimeZoneId } from '../utils/time';

const dateToHTMLDateString = (m: Date | number): string => {
  if (typeof m === 'number') {
    m = new Date(m);
  }
  const padWithZero = (num: number) => num.toString().padStart(2, '0');
  return `${m.getUTCFullYear()}-${padWithZero(m.getUTCMonth() + 1)}-${padWithZero(m.getUTCDate())}T${padWithZero(m.getUTCHours())}:${padWithZero(m.getUTCMinutes())}`;
}

const defaultDateFormat = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
  dateStyle: 'medium',
  timeZone: 'UTC'
});

export function applyHourOffsetToDate(offset: number, dt: Date | number) {
  const adjustment = offset * (3_600_000 /* 1 hour in millis */);
  return (typeof dt === 'number' ? dt : dt?.getTime()) + adjustment;
}

export default function PresetDateTimePicker(props: {
  disabled?: boolean,
  extraClasses?: string,
  showSelected?: boolean,
  onSelected?: (date?: number, alias?: string | JSX.Element) => void,
  dateFormat?: { format(date?: Date | number): string },
  options: { altTextForMenu?: string | JSX.Element, alias?: string | JSX.Element, date?: number }[],
  timezones?: { local: (typeof timezones)[number], reference?: (typeof timezones)[number] }
}) {
  const [opt, setOpt] = useState<number>(0);
  const [date, setDate] = useState<number | undefined>();
  let dateFormat = props.dateFormat ?? defaultDateFormat;
  const localTZ = props.timezones?.local ?? { offset: 0, abbr: 'UTC' };
  const targetTZ = props.timezones?.reference ?? localTZ;

  const opts = props.options.map(({ altTextForMenu, alias, date }, i) => {
    if (!date) {
      const title = altTextForMenu ?? alias;
      return <Listbox.Option
        onClick={() => props.onSelected?.(date, alias)}
        value={i}
        key={i}
        className={({ active }) => classNames(
          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
          'cursor-default select-none p-2')}>
        {() => <>
          <div>
            {typeof title === 'string' ?
              <span className="text-lg font-semibold">{title}</span>
              : title}
          </div>
        </>
        }
      </Listbox.Option>
    }
    const localDate = applyHourOffsetToDate(localTZ.offset, date);
    const targetDate = applyHourOffsetToDate(targetTZ.offset, date);

    return (
      <Listbox.Option
        value={i}
        key={i}
        onClick={() => props.onSelected?.(date, alias)}
        className={({ active }) => classNames(
          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
          'cursor-default select-none p-2 text-sm')}>
        {() => <>
          <div>
            <p className="text-lg font-semibold">{alias ?? dateFormat.format(targetDate)}</p>
            <p className="text-sm">{dateFormat.format(localDate)}&nbsp;({localTZ.abbr})</p>
            <p className="text-sm">{dateFormat.format(targetDate)}&nbsp;({targetTZ.abbr})</p>
          </div>
        </>
        }
      </Listbox.Option>
    )
  });

  function updateButtonText(opt: number) {
    setDate(props.options?.[opt]?.date);
    setOpt(opt);
  }

  return (
    <Listbox value={opt} onChange={updateButtonText} disabled={props.disabled}>
      {({ open }) => <div className='h-full'>
        <Listbox.Button className={classNames(
          props.disabled ? 'bg-gray-300' : 'bg-indigo-600',
          props.extraClasses,
          "border-gray-200 border w-auto text-white p-2 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50 h-full"
        )}>
          <div><ClockIcon className="h-5 w-5" /></div>
          <div hidden={!props.showSelected}><span>{props.options?.[opt as number]?.alias ?? dateFormat.format(date)}</span></div>
        </Listbox.Button>
        <Transition
          show={open}
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute right-2 z-10 mt-2 w-auto origin-top-left divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {opts}
          </Listbox.Options>
        </Transition>
      </div>}
    </Listbox>
  );
}

export function CustomDateTimePicker(props: {
  id?: string,
  name?: string,
  before?: number,
  after?: number,
  initialValue?: number,
  timeZoneId: TimeZoneId,
  referenceTimeZoneId?: TimeZoneId,
  dateFormat?: { format(date?: Date | number): string },
  onChange?: (date: number) => void,
}) {
  const L = useLocalizedStrings();
  const [valid, setValid] = useState(true);
  const initialValue = typeof props.initialValue === 'number' ? props.initialValue : Date.now();
  const [timezoneData, setTimezoneData] = useState(getTimeZoneData(props.timeZoneId, new Date(initialValue)));
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [referenceTimezoneData, setReferenceTimezoneData] = useState(getTimeZoneData(props.referenceTimeZoneId!, new Date(initialValue)));

  const dateFormat = props.dateFormat ||
    Intl?.DateTimeFormat
    ? new Intl.DateTimeFormat(undefined, {
      timeStyle: 'short',
      dateStyle: 'medium',
      timeZone: props.timeZoneId
    })
    : {
      format: (d: Date) => d.toLocaleString()
    };

  const referenceDateFormat = props.referenceTimeZoneId ? Intl?.DateTimeFormat
    ? new Intl.DateTimeFormat(undefined, {
      timeStyle: 'short',
      dateStyle: 'medium',
      timeZone: props.referenceTimeZoneId
    })
    : {
      format: (d: Date) => d.toLocaleString()
    }
    : undefined;

  const before = useMemo(
    () => typeof props.before === 'number' && timezoneData ? applyHourOffsetToDate(-timezoneData?.offset, props.before) : undefined,
    [props.before, timezoneData?.offset]
  );
  const after = useMemo(
    () => typeof props.after === 'number' && timezoneData ? applyHourOffsetToDate(-timezoneData?.offset, props.after) : undefined,
    [props.after, timezoneData?.offset]
  );
  const beforeDisplay = useMemo(
    () => typeof before === 'number' ? dateToHTMLDateString(before) : undefined,
    [before]
  );
  const afterDisplay = useMemo(
    () => typeof after === 'number' ? dateToHTMLDateString(after) : undefined,
    [after]
  );

  const outOfBoundsMsg = useMemo(
    () => {
      let msg = L.support.date_must_be_between;
      if (typeof before === 'number') {
        msg = msg.replace('$after', dateFormat.format(new Date(before)));
      }
      if (typeof after === 'number') {
        msg = msg.replace('$before', dateFormat.format(new Date(after)));
      }
      return msg;
    },
    [L.support.date_must_be_between, after, before]
  );

  useEffect(
    () => {
      if (timezoneData) {
        let selectedTimezoneData = getTimeZoneData(props.timeZoneId, new Date(selectedValue));
        if (selectedTimezoneData) {
          setTimezoneData(selectedTimezoneData);
        }
        const isValid = (!after || selectedValue >= after) && (!before || selectedValue <= before);
        setValid(isValid);
      }
      setReferenceTimezoneData(getTimeZoneData(props.referenceTimeZoneId!, new Date(selectedValue)));
      props.onChange?.(selectedValue);
    },
    [timezoneData, selectedValue, before, after]
  );

  if (!timezoneData) {
    return <div className="text-red-500">Failed to find timezone for "{props.timeZoneId}"</div>
  }

  return (<ul
    title={valid ? '' : outOfBoundsMsg}
    className={classNames("rounded-l-md m-0 divide-y divide-gray-300", !valid ? 'border-red-200 border-2' : '')}>
    <li className="flex justify-end m-0">
      <input id={props.id}
        name={props.name}
        type="datetime-local"
        className="bg-gray-50 cursor-pointer"
        value={dateToHTMLDateString(applyHourOffsetToDate(timezoneData.offset, selectedValue))}
        onChange={(e) => {
          if (!isNaN(e.target.valueAsNumber)) {
            setSelectedValue(applyHourOffsetToDate(-timezoneData.offset, e.target.valueAsNumber));
          }
        }}
        min={afterDisplay}
        max={beforeDisplay}
      />
      <span className="self-center">({timezoneData.abbr})</span>
    </li>
    {
      (referenceTimezoneData && referenceDateFormat) && <li className="flex justify-between m-0">
        <time>{referenceDateFormat.format(new Date(
          selectedValue
        ))}</time>
        <span className="ml-[18px]">({referenceTimezoneData.abbr})</span>
      </li>
    }
  </ul>);
}
