import React, { useContext, useEffect, useState } from "react";
import { ConfigurationContext, PublicConfigurationContext } from "./Context";
import { useHistory } from "react-router-dom";
import { usePost } from "./API";
import { Button, Heading, Icon, Paragraph, PasswordForm, useToast } from "@aidkitorg/component-library";
import { AidKitLogo, SpacedSpinner } from "./Util";
import { useLocalizedStrings } from "./Localization";
import { LanguageDropdown } from "./Components/LanguageDropdown";

export const ResetPasswordPage: React.FC = () => {
  const publicConfig = useContext(PublicConfigurationContext);
  const configuration = useContext(ConfigurationContext);
  const history = useHistory();
  const L = useLocalizedStrings();
  const { toast } = useToast();

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [status, setStatus] = useState<'default' | 'loading' | 'success' | 'invalid_token' | 'checkingToken'>('checkingToken');
    
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  if (!token || (publicConfig && publicConfig.adminPasswords === false)) {
    history.push("/");
  }

  const resetPassword = usePost("/authenticate/password/reset", { token: () => token!});
  const verifyResetToken = usePost('/authenticate/password/verify_reset_token', { token: () => token! });

  useEffect(() => {
    // On page load, check if the token is valid before asking the user for a new password
    const checkToken = async () => {
      const response = await verifyResetToken({});

      if (!response || response.status !== 'ok') {
        // If the token is invalid or expired, show the error message immediately
        setStatus('invalid_token');
      } else {
        // Inititialize the form as default once we know the token is valid
        setStatus('default');
      }
    };

    checkToken();
  }, []);

  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /\d/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>~`_\+\-=\[\]\\;]/;
  const specialCharLabel = (
    <div className="flex flex-col">
      <span>At least one special character</span>
      <span>{'[!@#$%^&*(),.?":{}|<>~`_\+\-=\[\]\\;]'}</span>
    </div>
  );

  const submitResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus('loading');

    try {
      const resetResponse = await resetPassword({
        password: newPassword,
      });

      if (resetResponse && resetResponse.status === 'ok') {
        setStatus('success');
      }
    } catch (error) {
      console.error(e);
      // This will only catch front-end errors
      toast({ description: "Error resetting password", variant: "error" });
      setStatus('default')
    }
  }

  const ResetPasswordForm = <>
    <Heading level="h3" className="text-center mb-2">
      {L.auth.reset_password}
    </Heading>
    <Paragraph className="text-center mt-0 font-medium text-gray-700">
      {L.auth.enter_your_new_password}
    </Paragraph>
    <PasswordForm
      loading={status === 'loading'}
      value={newPassword}
      onValueChange={(e) => setNewPassword(e.target.value)}
      confirmationValue={newPasswordConfirm}
      onConfirmationValueChange={(e) => setNewPasswordConfirm(e.target.value)}
      onSubmit={submitResetPassword}
      validationRules={
        [
          { regex: /.{16,}/, label: "At least 16 characters" },
          { regex: /^.{1,63}$/, label: "Less than 64 characters" },
          { regex: uppercaseRegex, label: "At least one uppercase character" },
          { regex: lowercaseRegex, label: "At least one lowercase character" },
          { regex: numberRegex, label: "At least one number" },
          { regex: specialCharRegex, label: specialCharLabel },
        ]
      }
    />
  </>;

  const SuccessMessage = <>
    <Icon name="CheckCircleIcon" role="decorative" size="xl" type="solid" className="mx-auto text-green-800 mb-2" />
    <Heading level="h3" className="text-center mb-3">
      {L.auth.reset_password_success}
    </Heading>
    <Paragraph className="mt-0 font-medium text-gray-700">
      {L.auth.reset_log_back_in}
    </Paragraph>
    <Button variant="secondary" onClick={() => history.push('/login')} className="w-full mt-4">{L.auth.reset_go_to_login}</Button>
  </>;

  const InvalidToken = <>
    <Icon name="ExclamationTriangleIcon" role="decorative" size="xl" type="solid" className="mx-auto text-amber-800 mb-2" />
    <Heading level="h3" className="text-center mb-3">
      {L.auth.expired_reset_link}
    </Heading>
    <Paragraph className="mt-0 font-medium text-gray-700">
      {L.auth.expired_reset_link_message}
    </Paragraph>
    <Button variant="secondary" onClick={() => history.push('/login')} className="w-full mt-4">{L.auth.reset_go_to_login}</Button>
  </>;

  const CheckingToken = <>
    <div className="flex flex-col min-h-[320px]">
      <Heading level="h3" className="text-center mb-3">
        {L.auth.checking_your_account}
      </Heading>
      <Paragraph className="mt-0 font-medium text-gray-700">
        {L.auth.checking_your_account_wait}
      </Paragraph>
      <div className="flex-grow flex items-center justify-center">
        <SpacedSpinner />
      </div>
    </div>
  </>

  if (publicConfig && publicConfig.adminPasswords) {
    const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configuration.applicant_facing_logo;
    const programName = publicConfig.name || configuration.program_name || 'AidKit Program';

    return (
      <div className="min-h-screen bg-gray-100">
        <div className="relative flex flex-col items-center md:flex-row md:items-start md:justify-center pb-2 px-2 md:pt-[12vh]">
          <div className="my-[1rem] md:my-0 md:absolute md:top-4 md:left-4">
            <LanguageDropdown languages={(configuration.languages || 'en,es').split(',')} />
          </div>
          <div className="w-[360px]">
            <div className="bg-white px-4 py-6 border-l border-r border-t rounded-t-lg border-gray-300">
              <div className="flex flex-col align-middle items-center mt-2 mb-4">
                {applicantFacingLogo ? (
                  <img
                    className="w-auto max-w-[180px] max-h-[100px]"
                    src={applicantFacingLogo}
                    alt={programName}
                  />
                ) : (
                  <AidKitLogo width={180} height={60} />
                )}
              </div>
              {status === 'checkingToken' && CheckingToken}
              {(status === 'default' || status === 'loading') && ResetPasswordForm}
              {status === 'success' && SuccessMessage}
              {status === 'invalid_token' && InvalidToken}
            </div>
            <div className="bg-slate-50 px-2 py-1 rounded-b-lg sm:px-6 sm:py-6 border-l border-r border-b border-gray-300">
              <footer>
                <Paragraph size='xs' className="mt-10 text-gray-500 px-3">
                  {L.auth.we_use_cookies}
                </Paragraph>
                <ul className="px-3 text-sm flex space-x-5">
                  <li><Button variant="link" href="/privacy.html">{L.privacy_policy}</Button></li>
                  <li><Button variant="link" href="/tos.html">{L.terms_and_conditions}</Button></li>
                </ul>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Return null unless we know for sure adminPasswords is enabled to prevent
  // flashing the form during the initial window where we haven't fully fetched the 
  // config.
  return null;
};